import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {setCurrentTime} from '../../redux/Slices/startFullTestSlice'

const Timer = () => {
    const dispatch = useDispatch();
    const currentTime= useSelector((state:RootState) =>  state.root.startFullTest.currentTime);
  useEffect(() => { 
    let intervalId = setInterval(() => {
        if(currentTime <= 0){
            clearInterval(intervalId);
            dispatch(setCurrentTime(0))
        }else{
            dispatch(setCurrentTime(currentTime-1))
        }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentTime]);
  const minutes = Math.floor(currentTime / 60);
  const seconds = currentTime % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  
  return <div>{displayTime}</div>;
};

export default Timer;
