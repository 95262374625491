import React from 'react'
import { getItem } from '../../utils/token'
import { IoIosLogOut } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const LogoutHeader = () => {
    const navigate = useNavigate()
  return (
    <div className="font-extrabold text-2xl "> {getItem("userName")} <button className="bg-[#F5F5F8] mx-3 font-light text-[#333333] px-4 py-2 rounded-full text-base" onClick={() => {
      localStorage.removeItem('token')
      navigate("/")}}>
        <div className="flex items-center justify-between gap-2"><IoIosLogOut /> Logout</div></button></div>
  )
}

export default LogoutHeader