import React from 'react'
import { openDialog } from '../../redux/Slices/dialogSlice'
import { useDispatch } from 'react-redux'
import { setCurrentClickedQuestion } from '../../redux/Slices/startFullTestSlice'

const ResultsTableContent = ({data}:any) => {
    const dispatch = useDispatch()
  return (
    <div>
            <table className="min-w-full bg-white ">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-7 px-4 border-b">Section</th>
                <th className="py-7 px-4 border-b">No</th>
                <th className="py-7 px-4 border-b">Question Type</th>
                <th className="py-7 px-4 border-b">Correct Answer</th>
                <th className="py-7 px-4 border-b">Your Choice</th>
                <th className="py-7 px-4 border-b">Explanation</th>
              </tr>
            </thead>
            <tbody>
                {data.map((item:any,index:any) => {
                  return<tr className={`hover:bg-gray-100 ${index % 2 === 0 ? "bg-[#5056ff11]" : ""}`} key={item.no}>
                    <td className={`py-7 text-center px-4 border-b `}>{item.section}</td>
                    <td className={`py-7 text-center font-semibold px-4 border-b`}>{item.no}</td>
                    <td className={`py-7 text-center px-4 border-b`}>{item.questionType}</td>
                    <td className={`py-7 text-center px-4 border-b`}>{item.correctAnswer}</td>
                    <td className={`py-7 text-center px-4 border-b font-semibold ${item.isCorrect ? "text-[#14ac51]"  : "text-[#d50f00]"}`}>{item.yourChoice}</td>
                    <td className={`py-7 text-center px-4 border-b`}><button className='px-4 py-2 text-white font-semibold bg-[#424242] rounded-full' onClick={() => {
                       dispatch(setCurrentClickedQuestion(item["_id"]))
                        dispatch(openDialog("resultsExplaination"))
                    }
                    }>Explanation</button></td>
                  </tr>
                })}
            </tbody>
          </table>
          </div>)
}

export default ResultsTableContent