import React from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { useNavigate } from "react-router-dom";
import { isDummyRender } from "../../redux/Slices/startFullTestSlice";


const testInstructions = [
  {
    id: 1,
    name: "Test Structure",
    icon: "",
    description:
      "This test is timed and structured like the actual Digital SAT, but you can save and exit the test any time.",
  },
  {
    id: 2,
    name: "Questions",
    icon: "",
    description:
      "Some questions contain facts, names, research findings, and artworks that are created solely for the purpose of this test and may not necessarily be accurate.",
  },
  {
    id: 3,
    name: "Adaptive Testing",
    icon: "",
    description:
      "Similar to the actual test, your performance in the first module of each section will determine the difficulty level of the second module.",
  },
];

const StartTestInstructions = () => {

const dispatch = useDispatch();
const navigate = useNavigate()

    const startTest = () => {
        dispatch(closeDialog());
        dispatch(isDummyRender(true))
        navigate("/practices/66")
        
    }
  return (
    <div className="flex flex-col px-4">
      {testInstructions.map((item: any) => {
        return (
          <div className="flex flex-col py-4 leading-7 " key={item.id}>
            <h3 className="font-bold opacity-85">{item.name}</h3>
            <p className="opacity-70">{item.description}</p>
          </div>
         
        );
      })}
      <div className="font-bold opacity-85 text-center py-4 "> Good Luck!</div>
      <CustomButton name="Start"  round ="lg" py="py-4" font = "font-semibold" onClickHandler={startTest}/>
    </div>
  );
};

export default StartTestInstructions;
