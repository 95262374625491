export const MathJaxDescription = () => (
    <>
    <div className="my-2"></div>
    <p>Bluebook implements MathJax technology to:</p>
        <div className="px-8">
                <li>Display mathematical content, such as expressions, equations, and inequalities elegantly in browsers.</li>
                <li>Provide students who use screen readers access to off-screen accessible MathML and Nemeth braille
                support with refreshable braille displays..</li>
                <li>Access a variety of accessibility features via menus attached to the MathJax content to assist students with low vision and students that need highlighted rendering.</li>
        </div>  
        <p className="my-6">MathJax objects within Bluebook exams are custom-configured to function with all mainstream text-to-speech and screen-reading assistive technologies across all supported operating systems. This custom configuration ensures that all mainstream assistive technologies will read the textual alternatives without any further configurations.</p>
        <div>
            <p className="my-7 font-bold">Optional MathJax configurations</p>
            
            <p>Some students may prefer to navigate complex math equations and math expressions with math viewers or plugins like MathCAT with personal screen readers like JAWS and NVDA. Users that want to explore the MathML content may choose "reset to default" within the MathJax menu by:</p>
            <div className="px-8">
                <li>. Focus on the math object with the keyboard, press the <span className="font-bold">Space</span> bar, and then choose <span className="font-bold">Math Settings {">"} Reset to defaults</span> from the menu, OR</li>
                <li>Right-click on the math object and choose Math Settings {">"} Reset to defaults from the menu.</li>
                <li><span className="font-bold">NOTE:</span> This action must be taken on each exam question. MathJax returns to Bluebook's automatic configuration whenever a new question is encountered.</li>
            </div>  

            <div className="my-7">
                <p>College Board allows students to activate or deactivate any accessibility features, and users can activate the features and settings to cater to their needs. To use the optional accessibility activation:</p>
                <div className="px-8">
                    <li>Focus on the math object with the keyboard, press the <span className="font-bold">Space</span> bar, and then
                    choose <span className="font-bold">Accessibility {">"} Activate</span> from the menu, OR</li>
                    <li>Right-click on the math object and choose <span className="font-bold">Accessibility {">"} Activate</span> from the menu.</li>
                    <li>Students will only need to set the MathJax activation setting once. However, students can make additional changes before or during an exam. (Note that resetting to default as described above will also deactivate the MathJax accessibility option and return to the Bluebook automatic configuration.)</li>
                </div>  
            </div>
        </div>
    </>
    );
    
    export default MathJaxDescription;