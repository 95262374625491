export const TestTimersDescription = () =>(
    <>
      <p className="my-4">
        During testing, a timer will let you know how much time remains for
        each module or part of the test.
      </p>
      <p className="my-4">
        At the end of section 1, you'll have a short break. During the break,
        we'll show you a timer counting down to the beginning of section 2.
      </p>
      <p className="my-4">
        When time runs out on section 2, the test will end, and your answers
        will be automatically submitted.
      </p>
    </>
  );
    
    export default TestTimersDescription;