import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../components/ReUsableComponents/CustomButton";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiEyeCloseLine,
} from "react-icons/ri";
import { RootState } from "../../redux/Store/store";
import {
  setCurrentStep,
  setUserDataErrors,
  setUserData,
  resetUserDataForm,
} from "../../redux/Slices/profileSetupSlice";
import { MdOutlineCheck, MdOutlineRemoveRedEye } from "react-icons/md";
import {
  convertToUSANumber,
  formatPhoneNumberToInternational,
  useEmailValidation,
  usePasswordValidation,
  usePhoneValidation,
} from "../../utils";
import {
  useCreateParentMutation,
  useGetUserDataByIdQuery,
  useLazyIsUserExistsQuery,
  useProfileVerificationMutation,
  useStudentProfileMutation,
  useUpdateUserMutation,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import { getItem, setItem } from "../../utils/token";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrCircleAlert } from "react-icons/gr";
import { jwtDecode } from "jwt-decode";
import AuthButton from "./LoginButton";

const ParentDetails = ({
  isParentRoute = false,
}: {
  isParentRoute?: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentStep, userData, selectedRole, userDataErrors } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id1");
  const decodedId = id ? atob(id) : null;

  const { validateEmail } = useEmailValidation();
  const { validatePhone } = usePhoneValidation();
  // Regex to match US phone number format: (123) 456-7890
  const [studentProfile] = useStudentProfileMutation();
  const [createParent] = useCreateParentMutation();
  const [updateUser] = useUpdateUserMutation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileVerification] = useProfileVerificationMutation();
  const { setPasswordError, passwordError, validatePassword } =
    usePasswordValidation();

  const {
    parentFirstName,
    parentLastName,
    parentUserName,
    parentPhoneNumber,
    parentPassword,
    studentFirstName,
    studentPhoneNumber,
  } = userData;
  useGetUserDataByIdQuery(decodedId, {
    refetchOnMountOrArgChange: true,
    skip: !isParentRoute || !decodedId,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setUserData({ [name]: value.replace(/\s+/g, " ").trimStart() }));
    dispatch(setUserDataErrors({ [name]: false }));
    setError("");
    setPasswordError("");
  };
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isUserExists] = useLazyIsUserExistsQuery();
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let formattedNumber = input;

    // Format input as (XXX) XXX-XXXX
    if (input.length >= 3) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    }
    if (input.length >= 6) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(
        3,
        6
      )}-${input.slice(6)}`;
    }
    if (input.length > 10) {
      formattedNumber = formattedNumber.slice(0, 14); // Limit to 10 digits
    }
    dispatch(setUserData({ parentPhoneNumber: formattedNumber }));
    dispatch(setUserDataErrors({ parentPhoneNumber: false }));
  };

  const validatePasswordMatch = () => {
    console.log("lojhgf");
    if (parentPassword !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    setError("");
    return true;
  };

  const checkIsEmailExists = async (value: string) => {
    try {
      const payload = {
        email: value,
        role: selectedRole === "parent" ? "STUDENT" : "PARENT",
      };
      await isUserExists(payload).unwrap();
      dispatch(setUserDataErrors({ parentUserName: false }));
      return false;
    } catch (error: any) {
      if (error?.originalStatus === 200) {
        dispatch(setUserDataErrors({ parentUserName: false }));
      } else {
        dispatch(setUserDataErrors({ parentUserName: true }));
      }
      if (error?.originalStatus === 200) {
        return false;
      } else {
        return true;
      }
    }
  };

  console.log(passwordError, "asdfg");

  const handleFinishClick = async () => {
    const newErrors = {
      parentFirstName: false,
      parentLastName: false,
      parentUserName: false,
      parentPhoneNumber: false,
      parentPassword: false,
    };
    if (isParentRoute) {
      newErrors.parentFirstName = !parentFirstName;
      newErrors.parentLastName = !parentLastName;
      newErrors.parentUserName = !parentUserName;
      newErrors.parentPassword = !parentPassword;
    }

    const isEmailValid = validateEmail(parentUserName);
    const isPhoneValid = validatePhone(parentPhoneNumber);
    const isPasswordvalid = validatePassword(parentPassword);
    const isPasswordsMatch = validatePasswordMatch();

    if (!isEmailValid) {
      newErrors.parentUserName = true;
    }

    if (!isPhoneValid) {
      newErrors.parentPhoneNumber = true;
    }

    if (isParentRoute && (!isPasswordvalid || !isPasswordsMatch)) {
      newErrors.parentPassword = true;
    }

    dispatch(setUserDataErrors(newErrors));

    if (
      !isEmailValid ||
      !isPhoneValid ||
      Object.values(newErrors).some(Boolean)
    ) {
      console.log("Validation failed.");
      return;
    }

    try {
      if (isParentRoute && isPasswordsMatch && isPasswordvalid) {
        setLoading(true);
        const payload = {
          ...userData,
          isParent: true,
          parentPhoneNumber:
            formatPhoneNumberToInternational(parentPhoneNumber),
        };
        const params = {
          userId: decodedId,
          verificationType: "INVITATION",
        };
        await profileVerification(params).unwrap();
        const res = await updateUser({
          userId: decodedId,
          payload,
        }).unwrap();
        if (res.status === "success") {
          navigate("/login");
        }
      } else {
        if (isParentRoute) return;
        setLoading(true);
        const userName = getItem("userEmail");
        const response = await studentProfile({
          ...userData,
          isParent: false,
          studentUserName: userName,
          studentPhoneNumber:
            formatPhoneNumberToInternational(studentPhoneNumber),
        }).unwrap();
        if (response.status === "success") {
          setItem("token", response?.data);
          const decoded: any = jwtDecode(response?.data);
          setItem("userName", decoded?.firstName);
          setItem("userRole", decoded?.role ?? null);
          setItem("userEmail", decoded?.userEmail);
          setItem("userName", studentFirstName);
        }
        if (parentUserName) {
          await createParent({
            ...userData,
            isParent: false,
            studentUserName: userName,
            parentPhoneNumber:
              formatPhoneNumberToInternational(parentPhoneNumber),
          }).unwrap();
        }
        navigate("/dashboard");
        setLoading(false);
        dispatch(resetUserDataForm());
      }
    } catch (error: any) {
      const errorMessage = error.data?.message || "An error occurred.";
      toast.error(errorMessage);
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleNextClick = () => {
    const newErrors = {
      parentFirstName: !parentFirstName,
      parentLastName: !parentLastName,
      parentUserName: selectedRole === "parent" ? false : !parentUserName,
    };
    dispatch(setUserDataErrors({ ...newErrors }));
    const isEmailValid = validateEmail(parentUserName);
    const isPhoneValid = validatePhone(parentPhoneNumber);

    if (!isEmailValid) {
      dispatch(setUserDataErrors({ parentUserName: true }));
    }

    if (!isPhoneValid) {
      dispatch(setUserDataErrors({ parentPhoneNumber: true }));
    }
    // Proceed if there are no errors
    if (
      !Object.values(newErrors).some(Boolean) &&
      isEmailValid &&
      isPhoneValid
    ) {
      dispatch(setCurrentStep("parentStudentDetails"));
    }
  };

  const handleActionButton = () => {
    const handleSubmit = {
      buttonText: "Finish",
      action: handleFinishClick,
    };

    const handleNext = {
      buttonText: "Next",
      action: handleNextClick,
    };

    return isParentRoute || currentStep === "studentParentDetails"
      ? handleSubmit
      : handleNext;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <div
      className={`font-gully ${
        isParentRoute
          ? "flex h-screen justify-center items-center flex-col"
          : ""
      }`}
    >
      {(currentStep === "studentParentDetails" || isParentRoute) && (
        <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
          Enter Parent’s Detail
        </p>
      )}
      {(error || passwordError) && (
        <div className="min-w-[446px] max-w-[520px] flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
          <div>
            <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
          </div>
          <p className="text-[#D00000] text-base font-semibold">
            {passwordError || error}
          </p>
        </div>
      )}
      <div className="w-[446px]">
        <CustomTextField
          onChangeHandler={handleChange}
          placeholder="Parent's First Name"
          type={"text"}
          name="parentFirstName"
          mandatory={true}
          value={parentFirstName}
          className="w-full !border-[#646464]"
          isError={userDataErrors.parentFirstName}
        />
        <CustomTextField
          onChangeHandler={handleChange}
          placeholder="Parent's Last Name"
          type={"text"}
          name="parentLastName"
          mandatory={true}
          value={parentLastName}
          className="w-full !border-[#646464]"
          containerClass="mt-6"
          isError={userDataErrors.parentLastName}
        />

        <CustomTextField
          onChangeHandler={handleChange}
          placeholder="Email"
          type={"text"}
          name="parentUserName"
          mandatory={true}
          disabled={selectedRole === "parent" || isParentRoute}
          value={
            selectedRole === "parent"
              ? getItem("userEmail")
              : parentUserName?.trim() || ""
          }
          className="w-full !border-[#646464]"
          containerClass="mt-6"
          isError={userDataErrors.parentUserName}
        />

        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e)
          }
          placeholder="Phone Number"
          type={"text"}
          name="parentPhoneNumber"
          mandatory={true}
          value={parentPhoneNumber}
          className="w-full !border-[#646464]"
          containerClass="mt-6"
          isError={userDataErrors.parentPhoneNumber}
        />
        {isParentRoute && (
          <>
            <div className="flex justify-between relative mt-6">
              <CustomTextField
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                placeholder="Password"
                type={`${showPassword ? "text" : "password"}`}
                name="parentPassword"
                value={parentPassword?.trim()}
                className="w-full !border-[#646464]"
                isError={userDataErrors.parentPassword}
              />
              {parentPassword && (
                <button
                  className={`${
                    userDataErrors.parentPassword && "text-[#D00000]"
                  } absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]`}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineRemoveRedEye />
                  ) : (
                    <RiEyeCloseLine />
                  )}
                </button>
              )}
            </div>
            <div className="flex justify-between relative mt-6">
              <CustomTextField
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                placeholder="Confirm Password"
                type={`${showConfirmPassword ? "text" : "password"}`}
                name="studentPassword"
                value={confirmPassword?.trim()}
                className="w-full !border-[#646464]"
                isError={!setError}
                onPaste={handlePaste}
              />
              {confirmPassword && (
                <button
                  className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <MdOutlineRemoveRedEye />
                  ) : (
                    <RiEyeCloseLine />
                  )}
                </button>
              )}
            </div>
          </>
        )}
        <div
          className={`${
            isParentRoute ? "justify-center" : "justify-between"
          } mt-9 flex items-center`}
        >
          {!isParentRoute && (
            <AuthButton
              text="Back"
              icon={<RiArrowLeftSLine />}
              iconPosition="left"
              onClick={() => dispatch(setCurrentStep("studentPersonalDetails"))}
              loader={false}
              disabled={currentStep === "parentPersonalDetails"}
            />
          )}

          <AuthButton
            text={handleActionButton()?.buttonText}
            icon={
              currentStep === "parentPersonalDetails" ? (
                <RiArrowRightSLine size={24} />
              ) : (
                <MdOutlineCheck size={24} />
              )
            }
            iconPosition="right"
            onClick={handleActionButton()?.action}
            loader={false}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ParentDetails;
