import  { useMemo } from 'react';
import TeX from "@matejmazur/react-katex";
import { HightLightText, HightLightTextData } from '../../redux/Slices/startFullTestSlice';
import { generateUniqueKey } from '../../utils/commonLogic';

const cleanInputString = (input:any) => {
  return input.replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/\s+/g, ' ').trim();
};
const extractLatexParts = (cleanedString:any) => {
  const latexPattern = /\$\~\s*(.*?)\s*\$\~/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = latexPattern.exec(cleanedString)) !== null) {
    if (match.index > lastIndex) {
      parts.push(cleanedString.slice(lastIndex, match.index));
    }
    parts.push(<TeX key={crypto.randomUUID()}>{match[1]}</TeX>);
    lastIndex = latexPattern.lastIndex;
  }

  if (lastIndex < cleanedString.length) {
    parts.push(cleanedString.slice(lastIndex));
  }

  return parts;
};

const processImageParts = (part:any, imageDetails:any, isOptions:any) => {
  const imgPattern = !isOptions ? /\{#(.*?)#\}/g : /\{(.*?)\}/g;
  const processedParts = [];
  let lastImgIndex = 0;
  let imgMatch;

  while ((imgMatch = imgPattern.exec(part)) !== null) {
    if (imgMatch.index > lastImgIndex) {
      processedParts.push(part.slice(lastImgIndex, imgMatch.index));
    }
    const imgSrc = imageDetails?.[imgMatch[1]];
    processedParts.push(
      imgSrc ? <img key={crypto.randomUUID()} src={imgSrc} alt="" /> : `{#${imgMatch[1]}#}`
    );
    lastImgIndex = imgPattern.lastIndex;
  }

  if (lastImgIndex < part.length) {
    processedParts.push(part.slice(lastImgIndex));
  }

  return processedParts;
};
const processPlaceholders = (subParts:any) => {
  return subParts.map((subPart:any) =>
    typeof subPart === 'string' ? subPart.split(':exp{}:exp').map((strPart, strIndex) =>
      strIndex > 0 ? [<span key={crypto.randomUUID()}>________</span>, strPart] : strPart
    ).flat() : subPart
  ).flat();
};
const processString = (inputString:any, imageDetails:any, isOptions:boolean) => {
  const cleanedString = cleanInputString(inputString);
  const parts = extractLatexParts(cleanedString);

  return parts.map((part) => {
    if (typeof part === 'string') {
      const processedParts = processImageParts(part, imageDetails, isOptions);
      return processPlaceholders(processedParts);
    }
    return part;
  }).flat();
};

const getHighlightData = (
  highlightedContent: HightLightTextData[],
  questionId: string,
  contentType: string
) => {
  return highlightedContent
    .find((data) => data.questionId === questionId)
    ?.hightLightText.filter(
      (highlight) => highlight.contentType === contentType
    )
    .sort((a, b) => a.startIndex - b.startIndex);
};

const createHighlightElement = (
  highlight: HightLightText,
  customColors: Record<string, string>,
  focusedColors: Record<string, string>,
  handleHighlightedTextClick: (
    highlightText: string,
    startIndex: number,
    e: React.MouseEvent
  ) => void
) => {

  const { startIndex, selectedText, bgColor } = highlight;
  const highlightKey = `${selectedText}-${startIndex}`;
  const spacedText = selectedText.split("").map((char) => (
    <span key={`${highlightKey}-${generateUniqueKey()}`}>{char === " " ? "\u00A0" : char}</span>
  ));
  const underline: "solid" | "double" | "dotted" | "dashed" | "wavy" | undefined =  highlight.underline === "none" ? undefined : (highlight.underline as "solid" | "double" | "dotted" | "dashed" | "wavy");

  return (
    <button
      key={highlightKey}
      className={`${customColors[bgColor]} focus:${focusedColors[bgColor]} cursor-auto`}
      style={{
        textDecorationLine: highlight.underline !== "none" ? "underline" : "none",
        textDecorationStyle: underline,
      }}
      tabIndex={0}
      onClick={(evt) => handleHighlightedTextClick(selectedText, startIndex, evt)}
    >
      {spacedText}
    </button>
  );
};

const renderHighlightedText = (
  textContent: string[],
  highlightedContent: HightLightTextData[],
  questionId: string,
  contentType: string,
  handleHighlightedTextClick: (
    highlightText: string,
    startIndex: number,
    e: React.MouseEvent
  ) => void
): (string | JSX.Element)[] => {
  const elements: (string | JSX.Element)[] = [];
  const highlightData = getHighlightData(highlightedContent, questionId, contentType);

  if (!highlightData) {
    return textContent.map((text) => <span key={text}>{text}</span>);
  }

  const customColors: Record<string, string> = {
    lightBlue: "bg-lightBlue",
    lightYellow: "bg-lightYellow",
    lightPink: "bg-lightPink",
  };

  const focusedColors: Record<string, string> = {
    lightBlue: "bg-[#0099ff]",
    lightYellow: "bg-[#ffff00]",
    lightPink: "bg-[#F799DE]",
  };

  textContent.forEach((text) => {
    let currentIndex = 0;

    highlightData.forEach((highlight) => {
      const { startIndex, selectedText } = highlight;

      if (startIndex >= currentIndex && startIndex < text.length) {
        if (currentIndex < startIndex) {
          elements.push(text.slice(currentIndex, startIndex));
        }

        elements.push(
          createHighlightElement(highlight, customColors, focusedColors, handleHighlightedTextClick)
        );

        currentIndex = startIndex + selectedText.length;
      }
    });

    if (currentIndex < text.length) {
      elements.push(text.slice(currentIndex));
    }

    elements.push(<br key={`br-${text}`} />);
  });

  return elements;
};


const RenderText = ({ text, imageDetails = {}, isOptions, contentType, highlightedContent, questionId, handleHighlightedTextClick  }: any) => {
  // Memoize the processed text to prevent unnecessary re-renders
  let processedText = useMemo(() => processString(text, imageDetails.imageDetails || {}, isOptions), [text, imageDetails, isOptions]);

  if(contentType === 'question' || contentType === 'description'){
    processedText = renderHighlightedText(processedText,highlightedContent,questionId,contentType,handleHighlightedTextClick);
  }

  return <div>{processedText}</div>;
};

export default RenderText;
