import { createSelector } from 'reselect';
import { RootState } from '../Store/store';

const selectStartTest = (state:RootState) => state.root.startFullTest;
const selectResultSlice = (state:RootState) => state.root.resultsSlice;
const selectLoadingSlice = (state:RootState) => state.root.loaderSlice;
const selectCounterSlice =(state:RootState) => state.root.counter;
const SelectModalSlice = (state:RootState) => state.root.dialog;
const pricingSlice = (state:RootState) => state.root.pricingSlice;
const questionBankSlice = (state:RootState) => state.root.questionBankSlice;


export const selectFullTestState = createSelector(
    [ selectStartTest,selectResultSlice,selectLoadingSlice,pricingSlice,questionBankSlice],
    (startTestSlice,resultSlice,loadingSlice,pricingSlice,questionBankSlice) => ({
      startTestSlice,
      resultSlice,
      loadingSlice,
      pricingSlice,
      questionBankSlice
    })
  );