import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "../../redux/Slices/dialogSlice";
import { RootState } from "../../redux/Store/store";
import CustomButton from "./CustomButton";
import { setCount, setIsPopUp, setOptionStrikerButton, setReviewPageClicked, setStrikedOptionsData, StrikedOptionsData, setIsHighlightNotes, setHighlightedContent, HightLightTextData, setHighlightPopupPosition, setHighlightPopup, handleSelectedText } from "../../redux/Slices/startFullTestSlice";
import "katex/dist/katex.min.css";
import MathsModuleDirections from "./MathsModuleDirections";
import Timer from "./Timer";
import { retrieveMathsModuleOneAnswers, retrieveMathsModuleTwoAnswers, retrieveReadingModuleOneAnswers, retrieveReadingModuleTwoAnswers, setAnswers } from "../../redux/Slices/resultSlice";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PopUp from "./PopUp";
import { useNavigate } from "react-router-dom";
import { getItem, removeItem, setItem } from "../../utils/token";
import RenderText from "./RenderText";
import QuestionsOverView from "./QuestionsOverView";
import { LiaCalculatorSolid } from "react-icons/lia";
import { TbMathFunction } from "react-icons/tb";
import referenceImage from "../../assets/Images/util-icon.png";
import Dropdown from "./Dropdown";
import { IoMdMore, IoMdClose, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FiHelpCircle } from "react-icons/fi";
import { LuKeyboard } from "react-icons/lu";
import DirectionModal from "./DirectionModal";
import saveIcon from "../../assets/Images/Save.jpg";
import CrossOutIcon from "../../assets/Images/CrossOutToggle.svg";
import CrossOutOnIcon from "../../assets/Images/CrossOutToggleOn.svg";
import OptionACrossOut from "../../assets/Images/OptionACrossOut.svg";
import OptionBCrossOut from "../../assets/Images/OptionBCrossOut.svg";
import OptionCCrossOut from "../../assets/Images/OptionCCrossOut.svg";
import OptionDCrossOut from "../../assets/Images/OptionDCrossOut.svg";
import ReviewOnIcon from "../../assets/Images/ReviewOnButton.svg";
import ReviewOffIcon from "../../assets/Images/ReviewOffButton.svg"
import ClockIcon from "../../assets/Images/clockIcon.svg"
import HighlitesandNotes from "../../assets/Images/highlitesandnotes.png";
import { LuHighlighter } from "react-icons/lu";
import { LuStickyNote } from "react-icons/lu";
import assistive_technology from "../../assets/Images/assistive_technology.svg"
import LineReaderIcon from "../../assets/Images/LineReaderIcon.svg";
import LineReader from "../Exam/LineReader";
import HighlightTextPopup, { NoteSection } from "./HighlightTextPopup";

export type OptionType = {
  label: string | ReactNode;
  onClick: () => void;
};

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}
interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  processedQuestion: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionType : string;
  questionTypeId: string;
  more: any;
  additionalProps: any;
}

interface CommonLayout {
  heading: () => React.ReactNode;
  timer: () => React.ReactNode;
  exit: () => React.ReactNode;
  children: () => React.ReactNode;
  textName: () => React.ReactNode;
  next: () => React.ReactNode;
  questionsList: ReadingModuleData[];
  moduleName: string;
  userAnswers: Answers[];
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface FormAnswerSet {
  answerOption: string;
  answerPlaceHolder: string;
  isActive: boolean;
  isCorrectAnswer: boolean;
}

interface SelectedTextProp{
  selectedText:string;
  startIndex:number|null;
}

const CommonTestModuleLayout = ({
  heading,
  timer,
  exit,
  children,
  textName,
  next,
  questionsList,
  moduleName,
  userAnswers,
}: CommonLayout) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    count,
    isPopUp,
    sidebarCollapse,
    optionStrikerButton,
    strikedOptionsData,
    writingModuleOneAnswers,
    writingModuleTwoAnswers,
    mathsModuleOneAnswers,
    mathsModuleTwoAnswers,
    reviewPageClicked,
    currentTime,
    isModuleCompleted,
    isHighlightNotes,
    highlightedContent,
    highlightPopup
  } = useSelector((state: RootState) => ({
    count: state.root.startFullTest.count,
    isPopUp: state.root.startFullTest.isPopUp,
    sidebarCollapse: state.root.startFullTest.sidebarCollapse,
    optionStrikerButton: state.root.startFullTest.optionStrikerButton,
    strikedOptionsData: state.root.startFullTest.strikedOptionsData,
    writingModuleOneAnswers: state.root.resultsSlice.writingModuleOneAnswers,
    writingModuleTwoAnswers: state.root.resultsSlice.writingModuleTwoAnswers,
    mathsModuleOneAnswers: state.root.resultsSlice.mathsModuleOneAnswers,
    mathsModuleTwoAnswers: state.root.resultsSlice.mathsModuleTwoAnswers,
    reviewPageClicked: state.root.startFullTest.reviewPageClicked,
    currentTime: state.root.startFullTest.currentTime,
    isModuleCompleted: state.root.startFullTest.isModuleCompleted,
    isHighlightNotes : state.root.startFullTest.isHighlightNotes,
    highlightedContent : state.root.startFullTest.highlightedContent,
    highlightPopup : state.root.startFullTest.highlightPopup
  }));

  const [hideTimer,setHideTimer] = useState(false);
  const [warningMessage,setWarningMessage] = useState(false);
  const [dismissedWarning, setDismissedWarning] = useState(false);
  const [dialogModalOpen, setDialogModalOpen] = useState(false);
  const [lineReaderCard,setLineReaderCard] = useState(false);
  const [openNotes,setOpenNotes] =useState(false);
  const userName = getItem("userName");

  const contentRef = useRef<HTMLDivElement>(null);
  const selectedText = useRef<SelectedTextProp>({
    selectedText:'',
    startIndex:null,
  });
  let updatedHighlightContent : HightLightTextData[] = JSON.parse(JSON.stringify(highlightedContent));
  
  const memoizedUserAnswers = useMemo(() => userAnswers, [userAnswers]);
  const saveExitDialog = () => {
    dispatch(openDialog("saveExitDialogue"));
  };
  const shortcutsDialog = () => {
    dispatch(openDialog("shortcutsDialog"));
  };
  const renderOptions = (answerOption: string) => {
    switch (answerOption) {
      case "Option A":
        return "A";
      case "Option B":
        return "B";
      case "Option C":
        return "C";
      case "Option D":
        return "D";
    }
  };

  const formAnswerSet = (
    answerItem: FormAnswerSet | string,
    uniqueQuestionId: string,
    questionType: string
  ) => {
    if (questionType === "MultipleChoice" && typeof answerItem === "object") {
      let tempAnswerObj = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem?.answerPlaceHolder,
          userOption: answerItem?.answerOption,
        },
      };
      dispatch(setAnswers(tempAnswerObj));

      //removal of the option from strikedoutoptions array.
      let strikedQuestionsData = strikedOptionsData?.map(item => ({
        ...item,
        strikedOptions: [...item.strikedOptions],
      }));
    
      const questionIndex = strikedQuestionsData?.findIndex((item) => item.questionId === uniqueQuestionId);
        if (questionIndex !== -1) {
          const optionIndex = strikedQuestionsData[questionIndex].strikedOptions.findIndex((opt) => opt === answerItem?.answerOption);
          if (optionIndex !== -1) {
            strikedQuestionsData[questionIndex].strikedOptions.splice(optionIndex, 1);
            dispatch(setStrikedOptionsData(strikedQuestionsData));
          }
        }
    }
    if (questionType === "ShortForm" && typeof answerItem === "string") {
      let tempAnswerObj2 = {
        module: moduleName,
        payload: {
          qId: uniqueQuestionId,
          userAnswer: answerItem,
          userOption: "",
        },
      };
      dispatch(setAnswers(tempAnswerObj2));
    }
  };

  const renderInputFieldValue = (qItem: any) => {
    let matchedQuestion = memoizedUserAnswers.find(
      (item) => item.qId === qItem["_id"]
    );
    return matchedQuestion?.userAnswer ? matchedQuestion?.userAnswer : "";
  };

  const getDescriptionWidth = () => {
    if(getNoteSectionOpen() && !openNotes){
      return 'w-[49%]'
    }

    if(openNotes && getNoteSectionOpen()){
      return 'w-[38%]'
    }
    return 'w-1/2'
  }
  
  const getNoteSectionOpen = () => {
    const existingQuestion : HightLightTextData | undefined = updatedHighlightContent.find(
      (item) => item.questionId === questionsList[count]._id
    );
  
    // Check if both `isHighlightNotes` is true and there's at least one item with `isNotes` as true
    return isHighlightNotes && existingQuestion?.hightLightText.some((item) => item.isNotes) || false;
  };

  const questionDescription = () => {
    return (
      <>
      <div className={`${getDescriptionWidth()} flex font-minion text-base`} >
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].description !==
          "student produced response directions" ? (
          <p className="pr-6 py-8 text-[#272727]" id="description">
            <RenderText
              text={questionsList[count].description}
              imageDetails={questionsList[count]}
              isOptions={false}
              contentType={'description'}
              highlightedContent={updatedHighlightContent}
              questionId={questionsList[count]._id}
              handleHighlightedTextClick={handleHighlightedTextClick}
            />
          </p>
        ) : (
          <div>
            <MathsModuleDirections />
          </div>
        )}
      </div>
      { getNoteSectionOpen() && (
        <div className={`${openNotes ? 'w-[13%]' : 'w-[1%]'} bg-lightGrey relative`}>
          <div className="flex w-8 h-6 px-3 py-2 items-center bg-gray-500 rounded-l-[30px] absolute bottom-11 -left-8">
            <button onClick={() => setOpenNotes(!openNotes)}>{openNotes ? <IoIosArrowForward className="w-5 h-5 text-white"/> :<IoIosArrowBack className="w-5 h-5 text-white"/>}</button>
          </div>
          <NoteSection NoteOpen={openNotes} highlightedContent={updatedHighlightContent} questionId={questionsList[count]["_id"]} selectedSentence={selectedText.current.selectedText} handleSetNotes={handleSetNotes}/>
        </div>)}
      </>
    );
  };

  const questionContent = () => {
    return (
      <div className="ml-4 w-1/2 py-8 pl-6 font-minion text-base" >
        {questionsList && questionsList.length > 0 && (
          <>
          <div className="flex border-b-2 border-dashed border-b-lightBlack bg-lightGrey">
            <div className="flex items-center self-stretch gap-6">
              <p className="border w-30 px-3 h-10 bg-black flex items-center justify-center text-white font-semibold">
                {count + 1}
              </p>
              <div className="flex items-center gap-3">
                <button onClick={() => handleMarkForReview(questionsList[count]["_id"])}><img src={getReviewIcon(questionsList[count]["_id"])} alt="review-icon" className="w-6 h-6"/></button>
                {""}Mark For Review
              </div>
            </div>
            {questionsList[count].questionType !== "Short Answer" && (
              <button
                onClick={() => dispatch(setOptionStrikerButton(!optionStrikerButton))}
                className="cursor-pointer ml-auto mr-2 relative group"
              >
                <img
                  src={optionStrikerButton ? CrossOutOnIcon : CrossOutIcon}
                  alt="cross-out-icon"
                  className="w-8 h-8"
                />{''}
                <span className="absolute bottom-full -right-32 font-minion transform -translate-x-1/2 mb-3 w-max px-3 py-1 text-xs text-white bg-gray-700 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity">
                  Cross out answer choices you think are wrong.{''}
                  <span className="absolute top-[70%] left-[90%] transform -translate-x-1/2 w-3 h-3 bg-gray-700 rotate-45"></span>
                </span>
              </button>
            )}
          </div>
          <div>
            <p className="mt-6" id="question">
              <RenderText
                text={questionsList[count].processedQuestion}
                imageDetails={questionsList[count]}
                isOptions={false}
                contentType={'question'}
                highlightedContent={updatedHighlightContent}
                questionId={questionsList[count]._id}
                handleHighlightedTextClick={handleHighlightedTextClick}
              />
            </p>
          </div>
          </>
        )}
        <div>
          {questionsList[count].answers.length > 0 ? (
            questionsList[count].answers?.map((answerItem: Answer, index) => {
              let matchedQuestion = memoizedUserAnswers.find(
                (item) => item.qId === questionsList[count]["_id"]
              );
              let selectedOption = questionsList[count].answers.find(
                (itm) => itm.answerOption === matchedQuestion?.userOption
              );
              let isSelected =
                answerItem.answerOption === selectedOption?.answerOption;
              let isStrikedOut = 
                optionStrikerButton ?  handleStrikedOut(questionsList[count]["_id"],answerItem.answerOption) : false;

              return (
                <div
                  className="flex w-full gap-[12px]"
                  key={answerItem.answerOption}
                >
                  <button
                    className={`border flex flex-1 gap-4 rounded-xl relative z-0 items-center ${
                      isSelected
                        ? "border-[#324DC7] border-[3px]"
                        : "border-lightBlack"
                    }  my-3  px-1 cursor-pointer`}
                    onClick={() =>
                      formAnswerSet(
                        answerItem,
                        questionsList[count]["_id"],
                        "MultipleChoice"
                      )
                    }
                    key={answerItem.answerOption}
                    disabled={isStrikedOut}
                  >
                    {isStrikedOut && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-[120%] h-[3px] bg-black -mx-2" />
                      </div>
                    )}

                    <div
                      className={`w-6 h-6 text-[13px] border-2 border-black m-2 mr-6 rounded-full flex justify-center items-center ${
                        isSelected
                          ? "bg-[#324DC7] text-white border-[3px] border-[#324DC7]"
                          : "text-black"
                      } ${isStrikedOut && "text-darkGrey border-darkGrey"}`}
                    >
                      {renderOptions(answerItem.answerOption)}
                    </div>
                    <div
                      className={`text-left py-3 ${
                        isStrikedOut && "text-darkGrey"
                      }`}
                    >
                      <p>
                        <p>
                          <RenderText
                            text={answerItem.answerPlaceHolder}
                            imageDetails={questionsList[count]}
                            isOptions={true}
                            contentType={'option'}
                            highlightedContent={[]}
                            questionId={questionsList[count]._id}
                            handleHighlightedTextClick={handleHighlightedTextClick}
                          />
                        </p>
                      </p>
                    </div>
                  </button>
                  {optionStrikerButton && (
                    <button
                      className="cursor-pointer"
                      onClick={() =>
                        handleStrikeOutOption(
                          questionsList[count]["_id"],
                          answerItem.answerOption
                        )
                      }
                    >
                      {isStrikedOut ? (
                        <p className="text-base font-bold underline">Undo</p>
                      ) : (
                        <img
                          src={getCrossOutImage(answerItem.answerOption)}
                          alt="cross-out-icon"
                          className="w-8 h-8"
                        />
                      )}
                    </button>
                  )}
                </div>
              );
            })
          ) : (
            <div className="m-4 ml-4">
              <input
                type="text"
                placeholder="Your Answer.."
                className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                value={renderInputFieldValue(questionsList[count])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formAnswerSet(
                    e.target.value,
                    questionsList[count]["_id"],
                    "ShortForm"
                  )
                }
              />
            </div>
          )}
        </div>
        <div></div>
      </div>
    );
  };

  const handleStrikeOutOption = (questionsId: string, option: string) => {
    let strikedQuestionsData = strikedOptionsData?.map(item => ({
      ...item,
      strikedOptions: [...(item.strikedOptions || [])], 
    })) || [];
  
    const questionIndex = strikedQuestionsData.findIndex(item => item.questionId === questionsId);
  
    if (questionIndex !== -1) {
      const strikedOptions = strikedQuestionsData[questionIndex].strikedOptions || [];
      const optionIndex = strikedOptions.findIndex(opt => opt === option);
  
      if (optionIndex !== -1) {
        strikedOptions.splice(optionIndex, 1);
      } else {
        strikedOptions.push(option);
      }
      strikedQuestionsData[questionIndex].strikedOptions = strikedOptions;
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    } else {
      const newStrikedQuestion: StrikedOptionsData = {
        questionId: questionsId,
        strikedOptions: [option],
        markedForReview: false,
      };
      strikedQuestionsData.push(newStrikedQuestion);
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    }
  
    // Handle removal of the option if it is selected as an answer
    let answers = getSelectedModuleAnswers(moduleName);
    let updatedAnswers = answers ? [...answers] : []; // Ensure answers is an array
    const answerIndex = updatedAnswers.findIndex(item => item.qId === questionsId);
  
    if (answerIndex !== -1 && updatedAnswers[answerIndex].userOption === option) {
      updatedAnswers.splice(answerIndex, 1);
      if (moduleName === "writingModuleOneAnswers") {
        dispatch(retrieveReadingModuleOneAnswers(updatedAnswers));
      } else if (moduleName === "writingModuleTwoAnswers") {
        dispatch(retrieveReadingModuleTwoAnswers(updatedAnswers));
      } else if (moduleName === "mathsModuleOneAnswers") {
        dispatch(retrieveMathsModuleOneAnswers(updatedAnswers));
      } else if (moduleName === "mathsModuleTwoAnswers") {
        dispatch(retrieveMathsModuleTwoAnswers(updatedAnswers));
      }
    }
  };

  const getSelectedModuleAnswers = (module : string) : Answers[] => {
    if (module === "writingModuleOneAnswers") {
       return writingModuleOneAnswers;
    } else if(module === "writingModuleTwoAnswers"){
       return writingModuleTwoAnswers;
    } else if(module === "mathsModuleOneAnswers"){
      return mathsModuleOneAnswers;
    } else if(module === "mathsModuleTwoAnswers"){
      return mathsModuleTwoAnswers;
    }
    return [];
  }
  
  const handleStrikedOut = (questionsId: string, option: string) => {
    let strikedQuestionsData = strikedOptionsData.map((item) => item) || [];
    const questionIndex = strikedQuestionsData.findIndex((item) => item.questionId === questionsId);
    if(questionIndex === -1){
      return false;
    }
    
    const strikedOptions = strikedQuestionsData[questionIndex].strikedOptions || [];
    const optionIndex = strikedOptions.findIndex((opt) => opt === option);
    if (optionIndex !== -1) {
      return true
    } else {
      return false;
    }
  }

  const getCrossOutImage = (optionItem : string) => {
    switch (optionItem) {
      case "Option A":
        return OptionACrossOut;
      case "Option B":
        return OptionBCrossOut;
      case "Option C":
        return OptionCCrossOut;
      case "Option D":
        return OptionDCrossOut;
    }
  }

  const handleMarkForReview = (questionsId : string) => {
    let strikedQuestionsData = strikedOptionsData.map(item => ({ ...item })) || [];
  
    const questionIndex = strikedQuestionsData.findIndex((item) => item.questionId === questionsId);
    if (questionIndex !== -1) {
      strikedQuestionsData[questionIndex].markedForReview = !strikedQuestionsData[questionIndex].markedForReview;
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    }else {
      const newStrikedQuestion: StrikedOptionsData = {
        questionId: questionsId,
        strikedOptions: [],
        markedForReview: true,
      };
      strikedQuestionsData.push(newStrikedQuestion);
      dispatch(setStrikedOptionsData(strikedQuestionsData));
    }
  }

  const getReviewIcon = (questionId : string) => {
    let ismarkedForReview = strikedOptionsData?.find((option) => option.questionId === questionId)?.markedForReview;
    return ismarkedForReview ? ReviewOnIcon : ReviewOffIcon;
  }
  	

  const handleCloseWarning = () => {
    setWarningMessage(false);
    setDismissedWarning(true);
  };

  const handleHighlight = () => {
    dispatch(setIsHighlightNotes(!isHighlightNotes));
  }

  const handleHighlightedTextClick = (highlightText: string,startIndex:number, e: React.MouseEvent) => {
    selectedText.current.selectedText = highlightText;
    selectedText.current.startIndex = startIndex;
    const rect = e.currentTarget.getBoundingClientRect();
    dispatch(setHighlightPopupPosition({ top: rect.top + window.scrollY, left: rect.left }));
    dispatch(setHighlightPopup(true)); // Open the modal on click
  };

  const handleTextSelection = () => {
    if (isHighlightNotes) {
      const selection = window.getSelection();

      if (selection?.toString()) { 
        const selectedText = selection.toString();
        const parentElement = selection.anchorNode?.parentElement;
        const superParentElement = parentElement?.closest('#question, #description');
        const parentId = superParentElement?.id;
        const questionId = questionsList[count]._id;
        const fullText = superParentElement?.textContent ?? "";

        const range = selection.getRangeAt(0);
        const startOffset = range.startOffset;
        const containerText = range.startContainer.textContent;

        let containerIndexInFullText = fullText.indexOf(containerText ?? "");

        const startIndex = containerIndexInFullText + startOffset;

        if (startIndex !== -1) {
          const payload = {
            selectedText,
            parentId,
            questionId,
            startIndex,
          };
          dispatch(handleSelectedText(payload));
        }
      }
    }
  };
  
  

  const handleSetNotes = (highlightedData : HightLightTextData) => {
    dispatch(setHighlightedContent(highlightedData));
  }
  
  const handleLineReader = () => {
    setLineReaderCard(!lineReaderCard)
  };

  function renderAnswersOrInput() {
    return (
      <div>
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].answers.length > 0 ? (
          questionsList[count].answers.map((answerItem: Answer, index) => {
            let matchedQuestion = memoizedUserAnswers.find(
              (item) => item.qId === questionsList[count]["_id"]
            );
            let selectedOption = questionsList[count].answers.find(
              (itm) => itm.answerPlaceHolder === matchedQuestion?.userAnswer
            );
            let isSelected =
              answerItem.answerOption === selectedOption?.answerOption;
            let isStrikedOut = 
              optionStrikerButton ?  handleStrikedOut(questionsList[count]["_id"], answerItem.answerOption) : false;
            return (
              <div
                className="flex w-full pb-[23px]"
                key={answerItem.answerOption}
              >
                <button
                  className={`border-2 min-w-96 flex rounded-md relative z-0 ${
                    isSelected
                      ? "border-[#324DC7] border-[3px]"
                      : "border-[#272727]"
                  }  px-1 cursor-pointer`}
                  onClick={() =>
                    formAnswerSet(
                      answerItem,
                      questionsList[count]["_id"],
                      "MultipleChoice"
                    )
                  }
                  key={answerItem.answerOption}
                  disabled={isStrikedOut}
                >
                  {isStrikedOut && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-[120%] h-[3px] bg-black -mx-2" />
                    </div>
                  )}

                  <div
                    className={`w-[25px] h-[25px] border-2 border-[#272727] m-2 mr-6 text-sm rounded-full font-semibold flex justify-center items-center ${
                      isSelected ? "bg-[#324DC7] text-white" : " text-black"
                    } ${isStrikedOut && "text-darkGrey border-darkGrey"}`}
                  >
                    <p>{renderOptions(answerItem.answerOption)}</p>
                  </div>
                  <p
                    className={`flex justify-center  items-center my-auto ${
                      isStrikedOut && "text-darkGrey"
                    }`}
                  >
                    <p>
                      <RenderText
                        text={answerItem.answerPlaceHolder}
                        imageDetails={questionsList[count]}
                        isOptions={true}
                        contentType={'option'}
                        highlightedContent={[]}
                        questionId={questionsList[count]._id}
                        handleHighlightedTextClick={handleHighlightedTextClick}
                      />
                    </p>
                  </p>
                </button>
                {optionStrikerButton && (
                  <button
                    className="cursor-pointer"
                    onClick={() =>
                      handleStrikeOutOption(
                        questionsList[count]["_id"],
                        answerItem.answerOption
                      )
                    }
                  >
                    {isStrikedOut ? (
                      <p className="text-base font-bold underline">Undo</p>
                    ) : (
                      <img
                        src={getCrossOutImage(answerItem.answerOption)}
                        alt="cross-out-icon"
                        className="w-8 h-8"
                      />
                    )}
                  </button>
                )}
              </div>
            );
          })
        ) : (
          <div className="m-4 ml-4">
            {questionsList && questionsList.length > 0 && (
              <input
                type="text"
                placeholder="Your Answer.."
                className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                value={renderInputFieldValue(questionsList[count])}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  formAnswerSet(
                    e.target.value,
                    questionsList[count]["_id"],
                    "ShortForm"
                  )
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
  const renderingQuestions = () => {
    return (
      <div className="px-11 text-lg leading-7 overflow-y-auto h-[100%] ">
        {questionsList &&
        questionsList.length > 0 &&
        questionsList[count].description ? (
          <div className="w-full flex text-[#272727]">
            {questionDescription()}
            <div
              style={{ height: `calc(100vh - 184px)` }}
              className="flex items-center"
            >
              <div className="w-[4px] bg-gray-500 h-full"></div>
            </div>
            {questionContent()}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col w-[696px] mx-auto px-12">
            {questionsList && questionsList.length > 0 && (
              <>
                <div className="flex w-full border-b-2 border-dashed border-b-lightBlack bg-lightGrey mt-10">
                  <div className="flex items-center self-stretch gap-6">
                    <p className="border w-30 px-3 h-10 bg-black flex items-center justify-center text-white font-semibold">
                      {count + 1}
                    </p>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() =>
                          handleMarkForReview(questionsList[count]["_id"])
                        }
                      >
                        <img
                          src={getReviewIcon(questionsList[count]["_id"])}
                          alt="review-icon"
                          className="w-6 h-6"
                        />
                      </button>
                      {""}Mark For Review
                    </div>
                  </div>
                  {questionsList[count].questionType !== "Short Answer" && (
                    <button
                      onClick={() =>
                        dispatch(setOptionStrikerButton(!optionStrikerButton))
                      }
                      className="cursor-pointer ml-auto mr-2"
                    >
                      <img
                        src={
                          optionStrikerButton ? CrossOutOnIcon : CrossOutIcon
                        }
                        alt="cross-out-icon"
                        className="w-7 h-7"
                      />
                    </button>
                  )}
                </div>
                <div>
                  <p className="mt-6 mb-[23px]">
                    <RenderText
                      text={questionsList[count].processedQuestion}
                      imageDetails={questionsList[count]}
                      contentType={'question'}
                      highlightedContent={updatedHighlightContent}
                      questionId={questionsList[count]._id}
                      handleHighlightedTextClick={handleHighlightedTextClick}
                    />
                  </p>
                </div>
              </>
            )}
            {renderAnswersOrInput()}
          </div>
        )}
      </div>
    );
  };

  const handleOpenPopup = () => {
    dispatch(setIsPopUp(!isPopUp));
  };
  const closePopUp = useCallback(() => {
    if (isPopUp) dispatch(setIsPopUp(false));
  }, [isPopUp]);

  const openCalculator = () => {
    const url = "https://www.desmos.com/calculator"; // URL of the calculator
    const name = "CalculatorWindow";
    const specs = "width=400,height=500,top=100,left=100,noopener"; // Customize the size and position

    if (url.startsWith("https://www.desmos.com")) {
      // Validate the URL
      window.open(url, name, specs);
    } else {
      console.error("Invalid URL");
    }
  };

  const openReferences = () => {
    const newPath = "/references"; // Change this to the desired path
    const newUrl = `${window.location.origin}${newPath}`;

    // Define the window specifications
    const name = "ReferenceWindow";
    const specs = "width=400,height=500,top=100,left=100"; // Customize the size and position

    // Open the new window
    window.open(newUrl, name, `${specs},noopener,noreferrer`);
  };

  const openInstructions = () => {
    switch (moduleName) {
      case "writingModuleOneAnswers":
      case "writingModuleTwoAnswers":
        dispatch(openDialog("writingInstructions"));
        break;
      case "mathsModuleOneAnswers":
      case "mathsModuleTwoAnswers":
        dispatch(openDialog("mathsInstructions"));
        break;
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      setItem("reload", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    if (getItem("reload")) {
      removeItem("reload");
      dispatch(closeDialog());
      navigate("/practices");
    } else {
      removeItem("reload");
      dispatch(closeDialog());
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      dispatch(closeDialog());
    };
  }, [navigate]);

  useEffect(() => {
    if (currentTime < 300 && !dismissedWarning) {
      setWarningMessage(true);
      setHideTimer(false);
    }
    return () => {
      setWarningMessage(false);
    };
  }, [currentTime]);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("mouseup", handleTextSelection);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("mouseup", handleTextSelection);
        setIsHighlightNotes(false);
      }
    };
  }, [isHighlightNotes,questionsList[count]?._id]);

  useEffect(() => {
    if(moduleName ===  "mathsModuleOneAnswers" || moduleName === "mathsModuleTwoAnswers"){
      setIsHighlightNotes(false);
    }
  },[moduleName,questionsList[count]?._id]);

  const options: OptionType[] = [
    {
      label: (
        <>
          <FiHelpCircle className="mr-4 text-xl" /> <span>Help</span>
        </>
      ),
      onClick: () => dispatch(openDialog("helpDialog")),
    },
     {
      label: (
        <>
          <LuKeyboard className="mr-4 text-xl" />
          <span>Shortcuts</span>
        </>
      ),
      onClick: () => shortcutsDialog(),
    },{
      label: (
        <>
         <img className="mr-4 text-xl" src= {assistive_technology} alt="assistivetechnology" />
          <span>Assistive Technology</span>
        </>
      ),
      onClick: () => dispatch(openDialog("assistiveTechnologyDialog")),
    },
    {
      label: (
        <>
          <img className="mr-4 text-xl" src= {LineReaderIcon} alt="Line-Reader-Icon" />
          <span>Line Readers</span>
        </>
      ),
      onClick: () => handleLineReader(),
    },
    {
      label: (
        <>
          <img className="mr-4" src={saveIcon} alt="save" />{" "}
          <span>Save & Exit</span>
        </>
      ),
      onClick: () => saveExitDialog(),
    }
   ];
   const handleDirectionModal = () => {
    setDialogModalOpen(!dialogModalOpen);
  };
   const renderTestContent = () => {
    if(count === questionsList.length || reviewPageClicked){
       return (
        <div className="w-1/2 mx-auto my-4">
          <div className="flex flex-col text-[#333] gap-4">
            <div className=" text-2xl text-center">Check Your Work</div>
            <div className="flex flex-col gap-2 text-base mx-auto my-2">
              <p>
                On test day, you won't be able to move on to the next module
                until time expires.
              </p>
              <p>
                For these practice tests, you can click{" "}
                <span className="font-semibold">Next</span> when you're ready
                to move on.
              </p>
            </div>
            {/* <div className="font-semibold mx-auto text-xl">
            {heading()}
          </div> */}
          </div>
          <QuestionsOverView
            questionsList={questionsList}
            userAnswers={userAnswers}
            count={count}
            navigation={false}
            heading={heading}
            strikedOutOptions={strikedOptionsData}
          />
        </div>
      )
    }

    if(currentTime <= 0 || isModuleCompleted){
      return "";
    }

    else{
      return (renderingQuestions())
    }
   }

   const getIsWritingModule = () => {
    if(moduleName === "writingModuleOneAnswers" ||
      moduleName === "writingModuleTwoAnswers"){
        return true;
      }
    return false;
   }
   
   
  return (
    <div className={`flex flex-col h-screen `}>

      {
        lineReaderCard && (<LineReader handleLineReader={handleLineReader} module={moduleName}/>)
      }

      {
        (isHighlightNotes && highlightPopup) && <HighlightTextPopup questionId={questionsList[count]._id} selectedText={selectedText.current.selectedText} startIndex={selectedText.current.startIndex}/>
      }
      <DirectionModal module={moduleName} onCloseHandler={handleDirectionModal} dialogOpen={dialogModalOpen}/>
      <div className="h-[78px] bg-[#E6EDF8] border-2 border-dashed border-b-lightBlack flex py-[14px] px-11 justify-between items-center">
        <div className="flex justify-between items-center w-[52%]">
          <div className=" flex flex-col">
            <div>{heading()}</div>
            <button
              className="flex cursor-pointer mt-3"
              onClick={handleDirectionModal}
            >
              Directions{" "}
              <div className="">
              {dialogModalOpen ? <MdKeyboardArrowUp/>:<MdKeyboardArrowDown />}
              </div>
            </button>
          </div>

          <div
            className={`flex ${
              hideTimer ? "w-[84px]" : "w-[76px]"
            } h-[45px] flex-col justify-between items-center`}
          >
            <img
              src={ClockIcon}
              alt="clock-icon"
              className={`w-5 h-5 shrink-0 ${hideTimer ? "block" : "hidden"}`}
            />
            <div
              className={`text-center text-base font-medium ${
                hideTimer ? "hidden" : "block"
              } ${currentTime < 300 && "text-red-700"}`}
            >
              <Timer />
            </div>
            {currentTime > 300 && (<button
              className="border border-lightBlack rounded-[50px] px-3 py-1 text-[11px] font-semibold w-fit gap-[10px] active:underline ease-linear"
              onClick={() => setHideTimer(!hideTimer)}
            >
              {hideTimer ? "Show" : "Hide"}
            </button>)}
          </div>
        </div>
        {warningMessage && (
          <div className="absolute left-[754px] top-28 w-[400px] h-[46px] bg-lightBlack flex px-[23px] justify-center items-center gap-[30px] rounded-md">
            <p className="font-inter text-base text-white font-semibold">
              5 minutes left in this part of the test.
            </p>
            <button onClick={() => handleCloseWarning()}>
              <IoMdClose className="w-6 h-6 text-white" />
            </button>
          </div>
        )}

        <div className="flex items-center w-[48%] justify-end">
          <div
            className={`pr-4 flex flex-col items-center gap-x-2 cursor-pointer ${
              moduleName === "writingModuleOneAnswers" ||
              moduleName === "writingModuleTwoAnswers"
                ? "hidden"
                : "flex"
            }`}
          >
            <LiaCalculatorSolid
              className="mb-2"
              size={20}
              onClick={openCalculator}
            />
            <div className="text-xs">Calculator</div>
          </div>
          <div
            className={`pr-4 flex flex-col cursor-pointer ${getIsWritingModule()
                ? "hidden"
                : "flex"
            }`}
          >
            <button
              onClick={openReferences}
              style={{
                border: "none",
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              className="flex items-center flex-col"
            >
              <TbMathFunction className="mb-2" size={20} />
              <div className="text-xs">Reference</div>
            </button>
          </div>
          <div className="flex items-center justify-center">
          { getIsWritingModule() && (
              <button onClick={handleHighlight} className={`${isHighlightNotes ? 'border-b-2 border-lightBlack pb-[2px]' : 'pb-1'} `}>
                <div className="flex items-center py-2 gap-1 justify-center">
                  <LuHighlighter className="text-xl" />
                  <LuStickyNote className="text-xl" />
                </div>
                <p className="text-xs">Highlight & Notes</p>
              </button>
            )}

            <button className="flex items-center">
              <div className="z-50 flex flex-col items-center">
                <Dropdown
                  buttonLabel={<IoMdMore className="text-[20px] mb-2" />}
                  options={options}
                />
                <p className="text-xs">More</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#1B2264] flex justify-center items-center text-xs font-semibold text-white h-[26px] py-2 text-center mx-[45px] rounded-b-2xl">
        THIS IS A PRACTICE TEST
      </div>
      <div className="flex-grow pb-20 overflow-hidden" ref={contentRef}>
        {renderTestContent()}
      </div>

      <div
        style={{
          width: sidebarCollapse ? "calc(100% - 224px)" : "100%",
        }}
        className="h-[80px] bg-[#E6EDF8] fixed border-t-2 bottom-0 border-dashed border-black flex justify-between items-center px-11 z-0"
      >
        <div className="flex items-center justify-between w-[55%]">
          <div className="text-xl">{userName}</div>
          {currentTime > 0 && (
            <button
              className={`font-bold relative cursor-pointer text-[13px] bg-black text-white ${
                count === questionsList.length || reviewPageClicked
                  ? "hidden"
                  : "flex justify-center items-center"
              }  border rounded-md  border-[#e4e4e4] px-4 py-3`}
              onClick={() => handleOpenPopup()}
            >
              Question {count + 1} of {questionsList?.length}
              <span className="ml-2">
                {isPopUp ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
              </span>
              <PopUp
                onClose={closePopUp}
                questionsList={questionsList}
                userAnswers={memoizedUserAnswers}
                heading={heading}
                count={count}
                isPopUp={isPopUp}
                strikedOutOptions={strikedOptionsData}
              />
            </button>
          )}
        </div>
        <div className="flex w-[45%] justify-end items-center">
          {count !== 0 && currentTime >= 0 && (
            <div>
              <CustomButton
                name="Back"
                onClickHandler={() => {
                  dispatch(setReviewPageClicked(false));
                  dispatch(setCount("decrement"));
                }}
                font="font-bold"
                width="text-sm"
                py="py-4 px-6"
                round="full"
              />
            </div>
          )}
          <div className="rounded-full ml-4">{next()}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonTestModuleLayout;
