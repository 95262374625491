export const RefreshableBrailleDisplaysDescription = () => (
    <>
      <p>
        RBDs are used to read text-only content on digital exams. Alternative
        text descriptions are embedded in the exams for all graphics. RBDs
        must be properly set up before they can be used by students. For
        information about installing and setting up RBDs, refer to the
        product's provided instructions and manuals.
      </p>
      
      <p className="my-6">
        Refreshable braille is not recommended for students that require
        tactile graphics. Students can request a braille exam or raised line
        drawings.
      </p>
    </>
  );
    
    export default RefreshableBrailleDisplaysDescription;