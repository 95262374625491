import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import RenderText from "../ReUsableComponents/RenderText";

const ExplanationInBank = () => {
  const dispatch = useDispatch();
  const {  questionBankSlice } = useSelector(selectFullTestState);
  const { currentQuestionBank, counter } = questionBankSlice;
  const [currentQuestion, setCurrentQuestion] = useState<any>({});

  useEffect(() => {
    let listOfQuestions = currentQuestionBank?.questions?.questionBankList;
    setCurrentQuestion(listOfQuestions?.[counter]);
  }, [counter, currentQuestionBank]);
const correctOption = () => {
    let correctAnswer = currentQuestion?.answers.find((item:any) => item.isCorrectAnswer)
    return correctAnswer?.answerOption
}

  return (
    <div className="flex flex-col gap-4">
      <div className="font-bold flex justify-between">
        <p>Explanation</p>
        <button onClick={() => dispatch(closeDialog())} className="cursor-pointer">
          X
        </button>
      </div>
      <div>
        <p className="text-lg text-gray-950">
          <RenderText
            text={currentQuestion?.explanation || ""}
            imageDetails={currentQuestion}
            isOptions={false}
          />
        </p>
      </div>
      <div>
        {currentQuestion?.answers?.length > 0 ? (
          <p className="font-semibold flex gap-3"><p>Correct Option :</p>
          <p className="text-green-800">{correctOption()}</p> </p>
        ) : (
          <p className="font-semibold flex gap-3">
            <p>Correct Answer:</p>
            <p className="text-green-800">
              <RenderText
                text={currentQuestion?.answerValue || ""}
                imageDetails={currentQuestion}
                isOptions={false}
              />
            </p>
          </p>
        )}
      </div>
    </div>
  );
};

export default ExplanationInBank;
