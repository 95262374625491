import React from "react";
import ReferencesImg from "../assets/Images/reference_maths.png";

const ReferenceMathsEquations = () => {
  return (
    <div className="m-3">
      <img src={ReferencesImg} alt="references" />
      <div className="flex flex-col gap-3">
        <p>The number of degrees of arc in a circle is 360.</p>
        <p>The number of radians of arc in a circle is 2π.</p>
        <p>
          The sum of the measures in degrees of the angles of a triangle is 180.
        </p>
      </div>
    </div>
  );
};

export default ReferenceMathsEquations;
