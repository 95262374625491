import { TfiMarkerAlt } from "react-icons/tfi";
export const HighlightsAndNotesDescription = () => (
    <>
      <p>
        <span className = "font-bold">
          <TfiMarkerAlt className="inline-flex items-center flex-wrap" />
          Highlights & Notes:
        </span>
        On all non-math questions, you can highlight text and leave yourself
        notes.
        <li className="px-8 my-2">
          Click on Highlights & Notes from the top right of the screen.
        </li>
        <li className="px-8 my-2">Select some text to highlight.</li>
        <li className="px-8 my-2">
          You can change the highlight color, add an underline, make a note,
          or delete the highlight.
        </li>
        <li className="px-8 my-2">
          When you're done, simply click outside the highlight.
        </li>
        <li className="px-8 my-2">
          You can click on a highlight any time to edit it.
        </li>
      </p>

      <p className="my-6">
        You cannot annotate pictures, buttons, answer content, or text that
        you enter. If the content you annotate is shared across multiple
        questions, you'll see your annotations on all of those questions. Your
        annotations will not be graded.
      </p>
    </>
  );

export default HighlightsAndNotesDescription;