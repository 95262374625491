const ModuleCompletionAlert = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <p className="text-primaryColor text-2xl mb-[26px]">
        This Module Is Over
      </p>
      <p className="mb-6">All your work has been saved</p>
      <p className="mb-6">You'll move on automatically in just a moment</p>
      <p>Do not refresh this page </p>
      <span className="spinner" />
    </div>
  );
};

export default ModuleCompletionAlert;
