import { createSlice,PayloadAction } from "@reduxjs/toolkit"
import { startTestApi } from "../API/StartTest/startTestApi"


interface Service {
    feature: string;
    isOffered: boolean;
    color: string;
  }
  
  interface QuestionBank {
    questionsCount: number;
    color: string;
  }
  
  interface More {
    questionBank: QuestionBank;
    duration: string;
    services: Service[];
  }
  
  interface Subscription {
    _id: string;
    name: string;
    testCount: number;
    createdDate: string; // Use Date type if you want to work with Date objects
    validityInDays: number;
    price: number;
    more: More;
    active: boolean;
  }
 interface Plan {
    pricingPlanData:Subscription[]
 }
  
  
const initialState:Plan = {
    pricingPlanData:[]
}

const pricingPlans = createSlice({
    name: 'pricingPlans',
    initialState,
    reducers:{
        setPricing:(state,action) => {
                state.pricingPlanData = action.payload
        }
    },
    extraReducers: builder =>{
        builder.addMatcher(startTestApi.endpoints.getPricingPlansData.matchFulfilled,
            (state: Plan, action: PayloadAction<{ data: Subscription[] }>) => {
                state.pricingPlanData = action.payload?.data;
              }
        )
    }
})

export const {setPricing} = pricingPlans.actions

export default pricingPlans.reducer