import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {
  HightLightTextData,
  setHighlightedContent,
  setHighlightPopup,
  setSelectionBgColor,
  handleDeleteSelectedSentence
} from "../../redux/Slices/startFullTestSlice";
import YellowCircle from "../../assets/Images/YellowCircle.svg";
import BlueCircle from "../../assets/Images/BlueCircle.svg";
import PinkCircle from "../../assets/Images/PinkCircle.svg";
import DeleteIcon from "../../assets/Images/PopupDelete.svg";
import AddIcon from "../../assets/Images/PopupAddNotes.svg";
import PinkDrop from "../../assets/Images/PinkDrop.svg";
import YellowDrop from "../../assets/Images/YellowDrop.svg";
import BlueDrop from "../../assets/Images/BlueDrop.svg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface PopupProps {
  questionId: string;
  startIndex: number|null;
  selectedText: string;
}

interface UnderlineProps {
  handleChangeUnderline: (underlineStyle: string) => void;
}

interface NoteSectionProps {
  NoteOpen: boolean;
  highlightedContent: HightLightTextData[];
  questionId: string;
  selectedSentence: string;
  handleSetNotes: (data: HightLightTextData) => void;
}

const HighlightTextPopup = ({ questionId, startIndex, selectedText }: PopupProps) => {
  const { highlightedContent, highlightPopupPosition } =
    useSelector((state: RootState) => ({
      highlightedContent: state.root.startFullTest.highlightedContent,
      highlightPopupPosition: state.root.startFullTest.highlightPopupPosition,
    }));

  const [underlineDropdown, setUnderlineDropdown] = useState<boolean>(false);

  const dispatch = useDispatch();

  const popupRef = useRef<HTMLDivElement>(null);

  let updatedHighlightedContent = [...highlightedContent];

  const getSelectedTextData = (qId: string, text: string, startIndex: number|null) => {
    let existingQuestionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === qId
    );
  
    if (existingQuestionIndex !== -1 && startIndex !== null) {
      let questionHighlights = [
        ...updatedHighlightedContent[existingQuestionIndex].hightLightText,
      ];
      let selected = questionHighlights.find(
        (item) => item.selectedText === text && item.startIndex === startIndex
      );
  
      if (selected) {
        return { selected, existingQuestionIndex, questionHighlights };
      }
    }
  
    return null;
  };
  
  const getBgColor = () => {
    let selectedTextData = getSelectedTextData(questionId, selectedText, startIndex);
    if (selectedTextData) {
      const { selected } = selectedTextData;
      return selected.bgColor;
    }
    return "";
  };

  const handleChangeColor = (color: string) => {
    let selectedTextData = getSelectedTextData(questionId, selectedText, startIndex);
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } = selectedTextData;

      const updatedSelected = { ...selected, bgColor: color };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText && highlight.startIndex === selected.startIndex
            ? updatedSelected
            : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
      dispatch(setSelectionBgColor(color));
    }
  };

  const handleChangeUnderline = (underline: string) => {
    let selectedTextData = getSelectedTextData(questionId, selectedText, startIndex);
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } =
        selectedTextData;

      const updatedSelected = { ...selected, underline: underline };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText  && highlight.startIndex === selected.startIndex ? updatedSelected : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
    }
  };

  const handleAddNote = () => {
    let selectedTextData = getSelectedTextData(questionId, selectedText, startIndex);
    if (selectedTextData) {
      let { selected, existingQuestionIndex, questionHighlights } =
        selectedTextData;

      const updatedSelected = { ...selected, isNotes: true };

      updatedHighlightedContent[existingQuestionIndex] = {
        ...updatedHighlightedContent[existingQuestionIndex],
        hightLightText: questionHighlights.map((highlight) =>
          highlight.selectedText === selectedText && highlight.startIndex === selected.startIndex ? updatedSelected : highlight
        ),
      };

      const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
      dispatch(setHighlightedContent(updatedData));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        dispatch(setHighlightPopup(false));
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div
      ref={popupRef}
      className="absolute z-50 bg-white flex rounded-[50px] shadow-custom-black w-72 h-12 justify-center items-center gap-[10px] shrink-0"
      style={{
        top:  `${Number(highlightPopupPosition.top) - 50}px`,
        left: `${Number(highlightPopupPosition.left) - 40}px`,
      }}
    >
      <button
        className="relative"
        onClick={() => handleChangeColor("lightYellow")}
      >
        <img src={YellowCircle} alt="yellow-circle" />
        {getBgColor() === "lightYellow" && (
          <img
            src={YellowDrop}
            alt="yellow-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="relative"
        onClick={() => handleChangeColor("lightBlue")}
      >
        <img src={BlueCircle} alt="blue-circle" />
        {getBgColor() === "lightBlue" && (
          <img
            src={BlueDrop}
            alt="blue-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="relative"
        onClick={() => handleChangeColor("lightPink")}
      >
        <img src={PinkCircle} alt="pink-circle" />
        {getBgColor() === "lightPink" && (
          <img
            src={PinkDrop}
            alt="pink-drop"
            className="absolute inset-0 m-auto"
          />
        )}
      </button>
      <button
        className="flex w-9 h-9 justify-center items-center gap-[2px] shrink-0 relative focus:outline-none focus:border-b-2 focus:border-black"
        onClick={() => setUnderlineDropdown(!underlineDropdown)}
      >
        <div className="flex flex-col justify-center items-center gap-[3px]">
          <p className="font-inter text-xs font-medium">U</p>
          <div className="h-[1px] w-[18px] bg-black"></div>
          <DashedLine />
          <DottedLine />
        </div>
        {underlineDropdown ? (
          <IoIosArrowUp className="w-4 h-2 text-black" />
        ) : (
          <IoIosArrowDown className="w-4 h-2 text-black" />
        )}
        {underlineDropdown && (
          <Underlinedropdown handleChangeUnderline={handleChangeUnderline} />
        )}
      </button>
      <button className="flex w-9 h-9 p-[6px] justify-center items-center rounded-[17.5px] border border-lightBlack" onClick={() => dispatch(handleDeleteSelectedSentence({questionId,selectedText,startIndex}))}>
        <img src={DeleteIcon} alt="delete-icon" />
      </button>
      <div className="w-[.5px] h-6 bg-darkGrey"></div>
      <button
        className="flex w-9 h-9 p-[6px] justify-center items-center rounded-[17.5px] border border-lightBlack"
        onClick={handleAddNote}
      >
        <img src={AddIcon} alt="Add-icon" />
      </button>
    </div>
  );
};

export default HighlightTextPopup;

export const DashedLine = () => {
  return (
    <div className="grid grid-cols-5 gap-0.5 w-[18px]">
      <div className="bg-black h-[1px] col-span-1"></div>
      <div className="bg-black h-[1px] col-span-3"></div>
      <div className="bg-black h-[1px] col-span-1"></div>
    </div>
  );
};

export const DottedLine = () => {
  return (
    <div className="grid grid-cols-7 gap-[1px] w-[18px]">
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
      <div className="bg-black h-[1px]"></div>
    </div>
  );
};

export const Underlinedropdown = ({
  handleChangeUnderline,
}: UnderlineProps) => {
  return (
    <div className="absolute top-10 -left-1 rounded-b-md shadow-custom-black flex w-11 flex-col justify-center items-center bg-white">
      <button
        className="flex py-1 items-center flex-col justify-center"
        onClick={() => handleChangeUnderline("solid")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <div className="bg-black w-[18px] h-[1px]"></div>
      </button>
      <button
        className="flex py-1 items-center flex-col justify-center"
        onClick={() => handleChangeUnderline("dashed")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <DashedLine />
      </button>
      <button
        className="flex py-1 items-center flex-col justify-center"
        onClick={() => handleChangeUnderline("dotted")}
      >
        <p className="font-inter text-xs font-medium">U</p>
        <DottedLine />
      </button>
      <button
        className="flex py-1 items-center flex-col justify-center text-xs font-light font-inter"
        onClick={() => handleChangeUnderline("none")}
      >
        None
      </button>
    </div>
  );
};

export const NoteSection = ({
  NoteOpen,
  highlightedContent,
  questionId,
  selectedSentence,
  handleSetNotes,
}: NoteSectionProps) => {
  const updatedHighlightedContent = [...highlightedContent];

  const getNoteItems = () => {
    const existingQuestion = updatedHighlightedContent.find(
      (item) => item.questionId === questionId
    );
    return (
      existingQuestion?.hightLightText.filter((item) => item.isNotes) ?? []
    );
  };

  const updateHighlightedContent = (
    questionIndex: number,
    updatedTextItems: any
  ) => {
    const updatedData = JSON.parse(JSON.stringify(updatedHighlightedContent));
    updatedData[questionIndex].hightLightText = updatedTextItems;
    handleSetNotes(updatedData);
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    text: string,
    startIndex:number
  ) => {
    const { value } = e.target;
    const questionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === questionId
    );

    if (questionIndex !== -1) {
      const questionHighlights =
        updatedHighlightedContent[questionIndex].hightLightText;
      const updatedTextItems = questionHighlights.map((highlight) =>
        highlight.selectedText === text && highlight.startIndex === startIndex
          ? { ...highlight, notes: value }
          : highlight
      );
      updateHighlightedContent(questionIndex, updatedTextItems);
    }
  };

  const handleDeleteNote = (text: string, startIndex : number) => {
    const questionIndex = updatedHighlightedContent.findIndex(
      (item) => item.questionId === questionId
    );

    if (questionIndex !== -1) {
      const questionHighlights =
        updatedHighlightedContent[questionIndex].hightLightText;
      const updatedTextItems = questionHighlights.map((highlight) =>
        highlight.selectedText === text && highlight.startIndex === startIndex
          ? { ...highlight, notes: "", isNotes: false }
          : highlight
      );
      updateHighlightedContent(questionIndex, updatedTextItems);
    }
  };

  const noteCards = getNoteItems();
  const customColors: Record<string, string> = {
    lightBlue: "bg-lightBlue",
    lightYellow: "bg-lightYellow",
    lightPink: "bg-lightPink",
  };

  return (
    <>
      {NoteOpen && (
        <div
          className="flex flex-col space-y-5 py-5 overflow-y-auto"
          style={{ height: `calc(100vh - 184px)` }}
        >
          {noteCards.map((item) => (
            <div
              key={item.selectedText}
              className="flex flex-col w-52 h-[72px] mx-auto items-start rounded-xl border border-lightBlack"
            >
              <div
                className={`flex h-9 px-[10px] py-2 items-center justify-between gap-[22px] self-stretch rounded-t-xl ${
                  customColors[item.bgColor]
                }`}
              >
                <p className="font-inter text-sm font-semibold">
                  {item.selectedText}
                </p>
                <button
                  className="flex w-7 h-7 justify-center items-center rounded-[17.5px] border bg-white border-lightBlack"
                  onClick={() => handleDeleteNote(item.selectedText,item.startIndex)}
                >
                  <img src={DeleteIcon} alt="delete-icon" />
                </button>
              </div>
              <input
                type="text"
                value={item.notes}
                className="flex px-[10px] py-[8.5px] items-center gap-[10px] self-stretch rounded-b-xl text-[#919191] font-inter text-xs font-medium"
                onChange={(e) => handleTextChange(e, item.selectedText,item.startIndex)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
