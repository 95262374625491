import React, { useCallback, useEffect } from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import {
  changeCurrentModuleName,
  setCount,
  setIsModuleCompleted,
  setIsPopUp,
  setReviewPageClicked,
  setSpentTime,
  setTestCompleted,
} from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDialog } from "../../redux/Slices/dialogSlice";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";

const MathModuleTwo = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const { count, mathModuleTwoQuestions, testId,reviewPageClicked } = startTestSlice;
  const navigate = useNavigate();
  const saveHandler = () => {
    // dispatch(openDialog("mathsInstructions"));
  };
  const currentTime = useSelector(
    (state: RootState) => state.root.startFullTest.currentTime
  );

  const heading = useCallback(() => {
    return (
      <div className="flex flex-col">
        <div className="text-sm font-semibold">Section 2,Module 2: Math</div>
      </div>
    );
  }, []);
  const timer = () => {
    return <div>9:25</div>;
  };
  const exit = () => {
    return (
      <div>
        <CustomButton
          name="Save & Exit"
          onClickHandler={saveHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-full"
          border="border-[#333]"
          py="py-2"
          round="full"
        />
      </div>
    );
  };
  const children = () => {
    return (
      <div className="flex justify-center items-center">
        Maths Module TWO Children Component
      </div>
    );
  };
  const textName = () => {
    return <div className="font-semibold"> {getItem("currentTestName")} </div>;
  };
  const nexxtClickHandler = () => {
    dispatch(setReviewPageClicked(false));
    if (count > mathModuleTwoQuestions.length - 1 || reviewPageClicked) {
      dispatch(
        setSpentTime({ field: "mathModuleTwoSpentTime", value: +currentTime })
      );
      navigate(`/practices/results/${testId}`);
      dispatch(setTestCompleted(true));
    } else {
      dispatch(setCount("Increment"));
    }
    dispatch(setIsPopUp(false));
  };

  const next = () => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          width="text-sm"
          py="py-4 px-6"
          round="full"
          hover="hover:bg-primaryColor"
        />
      </div>
    );
  };
  useEffect(() => {
    dispatch(changeCurrentModuleName("mathModule2"));
  // dispatch(openDialog("mathsInstructions"));
    dispatch(setIsModuleCompleted(false))
  }, []);
  useEffect(() => {
    if (currentTime <= 0) {
      setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      setTimeout(() => {
        navigate("/practices/results");
      }, 7000);
    }
  }, [currentTime]);
  return (
    <>
      {" "}
      <CommonTestModuleLayout
        heading={heading}
        timer={timer}
        exit={exit}
        textName={textName}
        questionsList={startTestSlice.mathModuleTwoQuestions}
        next={next}
        moduleName={"mathsModuleTwoAnswers"}
        userAnswers={resultSlice.mathsModuleTwoAnswers}
      >
        {children}
      </CommonTestModuleLayout>
    </>
  );
};

export default MathModuleTwo;
