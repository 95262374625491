import { useEffect, useState } from "react";
import CustomButton from "../../components/ReUsableComponents/CustomButton";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiEyeCloseLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import Dropdown from "./Dropdown";
import {
  resetUserDataForm,
  setCurrentStep,
  setUserData,
  setUserDataErrors,
} from "../../redux/Slices/profileSetupSlice";
import { MdOutlineCheck, MdOutlineRemoveRedEye } from "react-icons/md";
import {
  convertToUSANumber,
  formatPhoneNumberToInternational,
  useEmailValidation,
  usePasswordValidation,
  usePhoneValidation,
} from "../../utils";
import {
  useCountyGetAllQuery,
  useCreateStudentMutation,
  useGetCountySchoolByIdQuery,
  useGetUserDataByIdQuery,
  useLazyGetCountySchoolByIdQuery,
  useLazyIsUserExistsQuery,
  useParentProfileMutation,
  useProfileVerificationMutation,
  useUpdateUserMutation,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../../utils/token";
import { GrCircleAlert } from "react-icons/gr";
import { jwtDecode } from "jwt-decode";
import AuthButton from "./LoginButton";

const StudentDetails = ({
  isStudentRoute = false,
}: {
  isStudentRoute?: boolean;
}) => {
  const {
    currentStep,
    userData,
    countyData,
    schoolData,
    userDataErrors,
    selectedRole,
  } = useSelector((state: RootState) => state.root.profileSetupSlice);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isCountyDropdownOpen, setIsCountyDropdownOpen] = useState(false);
  const { validateEmail } = useEmailValidation();
  const { validatePhone } = usePhoneValidation();
  const [parentProfile] = useParentProfileMutation();
  const [createStudent] = useCreateStudentMutation();
  const [updateUser] = useUpdateUserMutation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [profileVerification] = useProfileVerificationMutation();
  const { setPasswordError, passwordError, validatePassword } =
    usePasswordValidation();

  const id = searchParams.get("id2");
  const decodedId = atob(id || "");

  useCountyGetAllQuery({}, { refetchOnMountOrArgChange: true });
  const [getCountySchoolById] = useLazyGetCountySchoolByIdQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useGetUserDataByIdQuery(decodedId, {
    refetchOnMountOrArgChange: true,
    skip: !isStudentRoute || !decodedId,
  });

  const {
    studentFirstName,
    studentLastName,
    studentUserName,
    studentPassword,
    studentPhoneNumber,
    county,
    school,
    parentFirstName,
    parentPhoneNumber,
  } = userData;
  useGetCountySchoolByIdQuery(county, { skip: !county });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setUserData({ [name]: value.replace(/\s+/g, " ").trimStart() }));
    // Clear the error when typing
    dispatch(setUserDataErrors({ [name]: false }));
    setError("");
    setPasswordError("");
  };
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isUserExists] = useLazyIsUserExistsQuery();

  const handleApiError = (error: any) => {
    if (error.data) {
      toast.error(error.data.message);
    }
    console.error(error, "API Error");
  };
  const handleParentProfile = async () => {
    const isEmailExists = await checkIsEmailExists(studentUserName);
    if (isStudentRoute || isEmailExists) {
      dispatch(setUserDataErrors({ studentUserName: true }));
      return;
    }
    try {
      setLoading(true);
      const userEmail = getItem("userEmail");
      const response = await parentProfile({
        ...userData,
        isParent: true,
        parentUserName: userEmail,
        parentPhoneNumber: formatPhoneNumberToInternational(parentPhoneNumber),
      }).unwrap();
      if (response.status === "success") {
        setItem("token", response?.data);
        const decoded: any = jwtDecode(response?.data);
        setItem("userName", decoded?.firstName);
        setItem("userRole", decoded?.role ?? null);
        setItem("userEmail", decoded?.userEmail);
      }
      const res: any = await createStudent({
        ...userData,
        isParent: true,
        parentUserName: userEmail,
        studentPhoneNumber:
          formatPhoneNumberToInternational(studentPhoneNumber),
      });

      if (res?.data) {
        setItem("userName", parentFirstName);
        toast.success(res.data);
        navigate("/dashboard");
        setLoading(false);
        resetUserDataForm();
      }
    } catch (error: any) {
      handleApiError(error);
    }
  };

  const validatePasswordMatch = () => {
    if (studentPassword !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    setError("");
    return true;
  };

  const checkIsEmailExists = async (value: string) => {
    try {
      const payload = {
        email: value,
        role: selectedRole === "parent" ? "STUDENT" : "PARENT",
      };
      await isUserExists(payload).unwrap();
      dispatch(setUserDataErrors({ studentUserName: false }));
      return false;
    } catch (error: any) {
      console.log(error, "error789");
      if (error?.originalStatus === 200) {
        dispatch(setUserDataErrors({ studentUserName: false }));
      } else {
        setError("User already exists with given email");
        dispatch(setUserDataErrors({ studentUserName: true }));
      }
      if (error?.originalStatus === 200) {
        return false;
      } else {
        setError("User already exists with given email");
        return true;
      }
    }
  };

  const handleSubmitClick = async () => {
    const newErrors = {
      studentFirstName: !studentFirstName.trim(),
      studentLastName: !studentLastName.trim(),
      studentUserName: !isStudentRoute && !studentUserName.trim(),
      county: !county,
      school: !school,
      studentPassword: isStudentRoute && !studentPassword.trim(),
      studentPhoneNumber: false,
    };
    const isEmailValid = validateEmail(studentUserName);
    const isPhoneValid = validatePhone(studentPhoneNumber);
    const isPasswordvalid = validatePassword(studentPassword);

    const isPasswordsMatch = validatePasswordMatch();

    if (!isEmailValid) {
      newErrors.studentUserName = true;
    }

    if (!isPhoneValid) {
      newErrors.studentPhoneNumber = true;
    }

    if (isStudentRoute && (!isPasswordvalid || !isPasswordsMatch)) {
      newErrors.studentPassword = true;
    }

    dispatch(setUserDataErrors({ ...newErrors }));
    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors && isEmailValid && isPhoneValid) {
      try {
        if (isStudentRoute && isPasswordsMatch && isPasswordvalid) {
          setLoading(true);
          const payload = {
            ...userData,
            isParent: false,
            studentPhoneNumber:
              formatPhoneNumberToInternational(studentPhoneNumber),
          };
          const params = {
            userId: decodedId,
            verificationType: "INVITATION",
          };
          await profileVerification(params).unwrap();
          const res = await updateUser({
            userId: decodedId,
            payload,
          }).unwrap();
          if (res.status === "success") {
            setLoading(false);
            navigate("/login");
          }
        } else {
          handleParentProfile();
        }
      } catch (error: any) {
        setLoading(false);
        handleApiError(error);
      }
    }
  };

  const handleNextClick = () => {
    const newErrors = {
      studentFirstName: !studentFirstName.trim(),
      studentLastName: !studentLastName.trim(),
      county: !county,
      school: !school,
    };
    dispatch(setUserDataErrors({ ...newErrors }));
    const isEmailValid = validateEmail(studentUserName);
    const isPhoneValid = validatePhone(studentPhoneNumber);

    if (isStudentRoute) {
      dispatch(setUserDataErrors({ studentPassword: true }));
    }

    if (!isPhoneValid) {
      dispatch(setUserDataErrors({ studentPhoneNumber: true }));
    }

    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors && isEmailValid && isPhoneValid) {
      dispatch(setCurrentStep("studentParentDetails"));
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let formattedNumber = input;

    if (input.length >= 3) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    }
    if (input.length >= 6) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(
        3,
        6
      )}-${input.slice(6)}`;
    }
    if (input.length > 10) {
      formattedNumber = formattedNumber.slice(0, 14); // Limit to 10 digits
    }

    dispatch(setUserData({ studentPhoneNumber: formattedNumber }));
    dispatch(setUserDataErrors({ studentPhoneNumber: false }));
  };
  const handleDropdownChange = (val: any, name: string) => {
    if (name === "county") {
      getCountySchoolById(val?.id);
      dispatch(setUserData({ [name]: val?.id }));
    } else {
      dispatch(setUserData({ [name]: val?.id }));
    }
    dispatch(setUserDataErrors({ [name]: false }));
    setIsCountyDropdownOpen(false);
    setOpen(false);
  };

  const handleActionButton = () => {
    const handleSubmit = {
      buttonText: "Submit",
      action: handleSubmitClick,
    };

    const handleNext = {
      buttonText: "Next",
      action: handleNextClick,
    };

    return isStudentRoute || currentStep === "parentStudentDetails"
      ? handleSubmit
      : handleNext;
  };

  const getSelectedName = (data: any, selected: any, name: string) => {
    const selectedItem = data?.find((item: any) => item.id === selected);
    if (name === "county") {
      return selectedItem ? selectedItem.countyName : "";
    }
    if (name === "school") {
      return selectedItem ? selectedItem.schoolName : "";
    }
    return "";
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <div
      className={`font-gully ${
        isStudentRoute
          ? "flex h-screen justify-center items-center flex-col"
          : ""
      }`}
    >
      {(currentStep === "parentStudentDetails" || isStudentRoute) && (
        <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
          Enter Student’s Detail
        </p>
      )}
      {(error || passwordError) && (
        <div className="min-w-[446px]  max-w-[520px] flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
          <div>
            <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
          </div>
          <p className="text-[#D00000] text-base font-semibold">
            {passwordError || error}
          </p>
        </div>
      )}
      <div className="w-[446px]">
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="Student's First Name"
          type={"text"}
          name="studentFirstName"
          mandatory={true}
          value={studentFirstName}
          isError={userDataErrors.studentFirstName}
          className={"w-full !border-[#646464]"}
        />
        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e)
          }
          placeholder="Student's Last Name"
          type={"text"}
          name="studentLastName"
          mandatory={true}
          value={studentLastName}
          className="w-full !border-[#646464]"
          isError={userDataErrors.studentLastName}
          containerClass="mt-6"
        />
        {(currentStep === "parentStudentDetails" || isStudentRoute) && (
          <CustomTextField
            onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e)
            }
            placeholder="Email"
            type={"text"}
            name="studentUserName"
            mandatory={true}
            value={studentUserName?.trim()}
            className="w-full !border-[#646464]"
            isError={userDataErrors.studentUserName}
            containerClass="mt-6"
            disabled={isStudentRoute}
            onBlur={(e) => checkIsEmailExists(e.target.value)}
          />
        )}

        <Dropdown
          getOption={(option: any) => `${option.countyName}`}
          onChangeHandler={handleDropdownChange}
          setOpen={setIsCountyDropdownOpen}
          open={isCountyDropdownOpen}
          selected={getSelectedName(countyData, county, "county")}
          options={countyData || []}
          isError={userDataErrors.county}
          label="County"
          name="county"
        />
        <Dropdown
          getOption={(option: any) => `${option.schoolName}`}
          onChangeHandler={handleDropdownChange}
          setOpen={setOpen}
          open={open}
          selected={getSelectedName(schoolData, school, "school")}
          options={schoolData || []}
          isError={userDataErrors.school}
          label="School"
          name="school"
        />

        <CustomTextField
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handlePhoneChange(e)
          }
          placeholder="Phone Number"
          type={"text"}
          name="studentPhoneNumber"
          value={studentPhoneNumber}
          className="w-full !border-[#646464]"
          isError={userDataErrors.studentPhoneNumber}
          containerClass="mt-6"
        />
        {isStudentRoute && (
          <>
            <div className="flex justify-between relative mt-6">
              <CustomTextField
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                placeholder="Password"
                type={`${showPassword ? "text" : "password"}`}
                name="studentPassword"
                value={studentPassword?.trim()}
                className="w-full !border-[#646464]"
                isError={userDataErrors.studentPassword}
              />
              {studentPassword && (
                <button
                  className={`${
                    userDataErrors.studentPassword
                      ? "text-[#D00000]"
                      : "text-[#646464]"
                  } absolute right-6 bottom-2 cursor-pointer text-2xl`}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineRemoveRedEye />
                  ) : (
                    <RiEyeCloseLine />
                  )}
                </button>
              )}
            </div>
            <div className="flex justify-between relative mt-6">
              <CustomTextField
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                placeholder="Confirm Password"
                type={`${showConfirmPassword ? "text" : "password"}`}
                name="studentPassword"
                value={confirmPassword?.trim()}
                className="w-full !border-[#646464]"
                isError={!setError}
                onPaste={handlePaste}
              />
              {confirmPassword && (
                <button
                  className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <MdOutlineRemoveRedEye />
                  ) : (
                    <RiEyeCloseLine />
                  )}
                </button>
              )}
            </div>
          </>
        )}

        <div
          className={`mt-9 flex items-center ${
            isStudentRoute ? "justify-center" : "justify-between"
          }`}
        >
          {!isStudentRoute && (
            <AuthButton
              text="Back"
              icon={<RiArrowLeftSLine />}
              iconPosition="left"
              onClick={() => dispatch(setCurrentStep("parentPersonalDetails"))}
              loader={false}
              disabled={currentStep === "studentPersonalDetails"}
            />
          )}
          <AuthButton
            text={handleActionButton()?.buttonText}
            icon={
              currentStep === "studentPersonalDetails" ? (
                <RiArrowRightSLine size={24} />
              ) : (
                <MdOutlineCheck size={24} />
              )
            }
            iconPosition="right"
            onClick={handleActionButton()?.action}
            loader={false}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
