import {configureStore} from'@reduxjs/toolkit';
import rootReducer from '../RootReducer/rootReducer';
import api from '../API/createApi';

export const store = configureStore({
    reducer: {
        root: rootReducer,
        api: api.reducer,
      },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch