import React, { useEffect, useState } from "react";
import CustomButton from "../components/ReUsableComponents/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetQuestionInQuestionBankQuery,
  useSaveAndExitQuestionBankMutation,
} from "../redux/API/StartTest/startTestApi";
import { useDispatch, useSelector } from "react-redux";
import { selectFullTestState } from "../redux/Selectors/selectorsUse";
import Loader from "../components/ReUsableComponents/Loader";
import ErrorPage from "./ErrorPage";
import RenderText from "../components/ReUsableComponents/RenderText";
import { setCounter } from "../redux/Slices/questionBankSlice";
import { openDialog } from "../redux/Slices/dialogSlice";
import { RootState } from "../redux/Store/store";

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface CheckedOption {
  answer: Answer;
  isCorrect: boolean;
}

const QuestionPageInBank = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { sidebarCollapse } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
  let { id } = params;
  const { refetch } = useGetQuestionInQuestionBankQuery(id); // Assuming this fetches data
  const { loadingSlice, questionBankSlice } = useSelector(selectFullTestState);
  const { isLoading, isError } = loadingSlice;
  const { currentQuestionBank, counter } = questionBankSlice;
  const dispatch = useDispatch();
  const [saveAndExitQuestionBank] = useSaveAndExitQuestionBankMutation({});
  // State to track the current question, selected option, input for short answer, etc.
  const [isChecked, setIsChecked] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<CheckedOption[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<Answer | null>(null);
  const [inputAnswer, setInputAnswer] = useState<string>("");
  const [isCorrectChecked, setIsCorrectChecked] = useState(false); // New state to track correct answer check

  // Function to render option letters (A, B, C, D)
  const renderOptions = (answerOption: string) => {
    switch (answerOption) {
      case "Option A":
        return "A";
      case "Option B":
        return "B";
      case "Option C":
        return "C";
      case "Option D":
        return "D";
      default:
        return "";
    }
  };

  // useEffect to handle question changes based on the counter
  useEffect(() => {
    let listOfQuestions = currentQuestionBank?.questions?.questionBankList;
    if (counter >= 0) {
      setCurrentQuestion(listOfQuestions?.[counter]);
    } else {
      setCurrentQuestion(listOfQuestions?.[0]);
    }

    setIsChecked(false); // Reset check state on new question
    setSelectedOption(null); // Reset selected option on new question
    setCheckedOptions([]); // Reset checked options on new question
    setInputAnswer(""); // Reset input answer on new question
    setIsCorrectChecked(false); // Reset correct answer check on new question
  }, [counter, currentQuestionBank]);

  // Handle the "Check" button click
  const handleCheck = () => {
    if (selectedOption || inputAnswer) {
      if (currentQuestion?.answers?.length > 0) {
        // MCQ Type Question
        if (selectedOption) {
          const isCorrect = selectedOption.isCorrectAnswer;
          setCheckedOptions([
            ...checkedOptions,
            { answer: selectedOption, isCorrect },
          ]);
          setIsChecked(true);
          setIsCorrectChecked(isCorrect); // Set correct answer check
        }
      } else if (inputAnswer) {
        // Short Answer Type Question

        const isCorrect =
          inputAnswer.trim().toLowerCase() ===
          currentQuestion.answerValue.trim().toLowerCase();
        setCheckedOptions([
          ...checkedOptions,
          {
            answer: {
              answerOption: inputAnswer,
              isCorrectAnswer: isCorrect,
            } as Answer,
            isCorrect,
          },
        ]);
        setIsChecked(true);
        setIsCorrectChecked(isCorrect); // Set correct answer check
      }
    } else {
      alert("Please select an option"); // Show alert if no option or answer is selected
    }
  };

  const saveAndExitQuestionBankApi = async (payload: any) => {
    await saveAndExitQuestionBank(payload);
  };

  // Handle the "Next" button click
  const handleNext = () => {
    // Logic to go to the next question
    if (counter < currentQuestionBank.questions?.questionBankList.length - 1) {
      dispatch(setCounter(counter + 1));
    } else {
      let payload: any = {
        currentQId: counter + 1,
        isTestCompleted: true,
        userQuestionBankId: currentQuestionBank?.userQuestionBankId,
      };
      setCounter(0);
      navigate(`/categories`);
      saveAndExitQuestionBankApi(payload);

      navigate(`/categories`); // or wherever you want to navigate after the last question
    }
  };

  // Function to evaluate if the selected option or input answer is correct
  const evaluateAnswer = (option?: Answer) => {
    if (currentQuestion?.answers?.length > 0) {
      return option?.isCorrectAnswer ?? false;
    } else if (currentQuestion.answerValue) {
      // Ensure answerValue is defined before accessing trim
      return (
        inputAnswer.trim().toLowerCase() ===
        currentQuestion.answerValue.trim().toLowerCase()
      );
    }
    return false;
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : isError ? (
        <ErrorPage />
      ) : (
        <div className="h-full">
          <header
            style={{
              width: sidebarCollapse
                ? "calc(100% - 224px)"
                : "calc(100% - 56px)",
            }}
            className="h-[10%] fixed top-0 border border-red-700"
          >
            <div className="px-6 py-6 flex justify-between bg-gradient-to-r from-[#fbfcff] to-[#eeeffc]">
              <div className="flex flex-col gap-2">
                <p className="text-primaryColor font-semibold">Question Bank</p>
                <p className="font-bold text-xl">
                  {currentQuestionBank.questionContentType}
                </p>
              </div>
              <div>
                <CustomButton
                  name="Save & Exit"
                  onClickHandler={async () => {
                    let qID;
                    if (selectedOption?.isCorrectAnswer && isCorrectChecked) {
                      qID = counter + 1;
                    } else {
                      qID = counter;
                    }
                    let payload: any = {
                      currentQId: qID,
                      isTestCompleted: false,
                      userQuestionBankId:
                        currentQuestionBank?.userQuestionBankId,
                    };
                    setCounter(0);
                    navigate(`/categories`);
                    await saveAndExitQuestionBank(payload);
                  }}
                  bg="bg-primaryColor"
                  font="font-semibold"
                  color="white"
                />
              </div>
            </div>
          </header>
          <body
            style={{
              width: sidebarCollapse
                ? "calc(100% - 224px)"
                : "calc(100% - 56px)",
            }}
            className="fixed top-[11%] px-6 py-6 h-full"
          >
            <div className="flex h-[80%]">
              <div
                className={` ${
                  currentQuestion?.description
                    ? "border-r border-[#e4e4e4] w-1/2"
                    : "w-0"
                } `}
              >
                {currentQuestion?.description && (
                  <div className="text-xl font-bold mb-2">
                    Question {currentQuestion?.qId}
                  </div>
                )}
                <p className="text-lg text-gray-950">
                  <RenderText
                    text={currentQuestion?.description || ""}
                    imageDetails={currentQuestion}
                    isOptions={false}
                  />
                </p>
              </div>
              <div
                className={`pl-8 ${
                  currentQuestion?.description ? "w-1/2" : "w-full"
                }`}
              >
                {!currentQuestion?.description && (
                  <div className="text-xl font-bold mb-2">
                    Question {currentQuestion?.qId}
                  </div>
                )}
                <div>
                  <p className="text-lg text-gray-950">
                    <RenderText
                      text={currentQuestion?.processedQuestion || ""}
                      imageDetails={currentQuestion}
                      isOptions={false}
                    />
                  </p>
                  <div>
                    {currentQuestion?.answers?.length > 0 ? (
                      currentQuestion?.answers?.map((answerItem: Answer) => {
                        const isSelected =
                          answerItem.answerOption ===
                          selectedOption?.answerOption;
                        const previouslyCheckedOption = checkedOptions.find(
                          (checked) =>
                            checked.answer.answerOption ===
                            answerItem.answerOption
                        );
                        const wasPreviouslyChecked =
                          previouslyCheckedOption !== undefined;
                        const isCorrectAnswer =
                          wasPreviouslyChecked &&
                          previouslyCheckedOption?.isCorrect;
                        const isWrongAnswer =
                          wasPreviouslyChecked &&
                          !previouslyCheckedOption?.isCorrect;

                        return (
                          <button
                            className={`border-2 min-w-96 flex rounded-md ${
                              isSelected && !isChecked
                                ? "border-[#007aff]" // Blue for current selection
                                : isCorrectAnswer
                                ? "border-green-500" // Green for previous correct selection
                                : isWrongAnswer
                                ? "border-red-500" // Red for previous incorrect selection
                                : "border-[#e4e4e4]" // Default border
                            }  my-6  px-1 cursor-pointer`}
                            onClick={() => {
                              setSelectedOption(answerItem); // Set the new selected option
                              setIsChecked(false); // Reset the isChecked state to allow re-evaluation
                              setIsCorrectChecked(false); // Reset the correct answer check for the new selection
                            }}
                            key={answerItem.answerOption}
                          >
                            <div
                              className={`w-11 h-10 border m-2 rounded-full flex justify-center items-center ${
                                isSelected && !isChecked
                                  ? "bg-[#007aff] text-white" // Blue for current selection
                                  : isCorrectAnswer
                                  ? "bg-green-500 text-white" // Green for previous correct selection
                                  : isWrongAnswer
                                  ? "bg-red-500 text-white" // Red for previous incorrect selection
                                  : "bg-slate-100 text-black" // Default background
                              }`}
                            >
                              {renderOptions(answerItem.answerOption)}
                            </div>
                            <div className="flex justify-center items-center my-auto">
                              <p>
                                <p>
                                  <RenderText
                                    text={answerItem.answerPlaceHolder}
                                    imageDetails={currentQuestion}
                                    isOptions={true}
                                  />
                                </p>
                              </p>
                            </div>
                          </button>
                        );
                      })
                    ) : (
                      <div className="m-4 ml-4">
                        <input
                          type="text"
                          placeholder="Your Answer.."
                          className="border mx-2 border-[#e4e4e4] p-2 mt-2 rounded-md"
                          value={inputAnswer}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setInputAnswer(e.target.value)
                          }
                        />
                        {isChecked && (
                          <div
                            className={`mt-2 ${
                              evaluateAnswer()
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {evaluateAnswer()
                              ? "Correct Answer"
                              : `Incorrect, the correct answer is: ${currentQuestion.answerValue}`}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </body>
          <footer
            style={{
              width: sidebarCollapse
                ? "calc(100% - 224px)"
                : "calc(100% - 56px)",
            }}
            className="h-[10%] fixed bottom-0 border-t border-[#e4e4e4]"
          >
            <div className="px-8 py-6 flex justify-between">
              <div>{}</div>
              <div></div>
              <div className="flex justify-between items-center">
                <button
                  className="mx-5 underline text-primaryColor text-xl cursor-pointer"
                  onClick={() =>
                    dispatch(openDialog("explanationQuestionInBank"))
                  }
                >
                  Show Explanation
                </button>
                <span>
                  <CustomButton
                    name={
                      isCorrectChecked
                        ? "Next >"
                        : isChecked
                        ? "Check Again >"
                        : "Check >"
                    }
                    onClickHandler={() => {
                      if (isCorrectChecked) {
                        handleNext(); // Move to the next question
                      } else {
                        handleCheck(); // Check or re-check the current answer
                      }
                    }}
                    bg={isCorrectChecked ? "bg-green-500" : "bg-primaryColor"}
                    font="font-semibold"
                    color="white"
                  />
                </span>
              </div>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default QuestionPageInBank;
