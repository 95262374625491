import React from "react";
import { getItem } from "../utils/token";

const Dashboard = () => {
  const userName = getItem("userName");
  return (
    <div
      style={{ height: "calc(100vh - 224px)" }}
      className="flex items-center justify-center overflow-hidden flex-col"
    >
      <p className="text-3xl">
        Welcome <span className="text-[blue] font-bold">{userName ?? ""}</span>
      </p>
      <p className="mt-6">
        We’re excited to have you on your journey to success.
      </p>
    </div>
  );
};

export default Dashboard;
