export const RestartingTheAppDescription = () => (
    <>
      <p>
        If the exam app crashes during your exam, just relaunch it and hit{" "}
        <span className="font-bold">Resume Testing</span> to get back to the question you were
        on. The testing timer will be paused until you resume testing.{" "}
      </p>
    </>
  );
    
    export default RestartingTheAppDescription;