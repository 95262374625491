export const ScreenMagnificationDescription = () => (
    <>
      <p>
        Screen magnification technology enlarges the content displayed on the
        computer screen in order to assist students with visual impairments.
        Although the exam app supports some non-embedded screen magnifier
        tools from third parties, all users can zoom using their device's
        native controls (Control +/- on a PC; Command +/- on a Mac; pinch and
        zoom on an iPad).
      </p>
    </>
  );
    
    export default ScreenMagnificationDescription;