
import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {setItem} from "../../utils/token"
import { startTestApi } from "../API/StartTest/startTestApi";

interface Answer {
    isActive: boolean;
    answerOption: string;
    isCorrectAnswer: boolean;
    correctAnswer: string;
    answerPlaceHolder: string;
  
}

interface ReadingModuleData{
    _id: string;
    isActive: boolean;
    description: string;
    question: string;
    processedQuestion:string;
    explanation: string;
    moduleId: string;
    sectionId: string;
    answers: Answer[];
    questionType : string;
    questionTypeId: string;
    more: any; // You might want to replace 'any' with a more specific type
    additionalProps: any; 
}
// Define the specific keys you want to allow in the payload
type SpentTimeFields = 'readingAndWritingModuleOneSpentTime' | 
                      'readingAndWritingModuleTwoSpentTime' | 
                      'mathModuleOneSpentTime' | 
                      'mathModuleTwoSpentTime';

// Define the action payload type
interface SetSpentTimePayload {
  field: SpentTimeFields;
  value: number;
}

interface TestData{
testid:string;
userTestId:string;
name:string;
isFreeTest:boolean;
testType:string;
isTestStarted:boolean
}

export interface StrikedOptionsData{
 questionId : string;
 strikedOptions : string[];
 markedForReview : boolean;
}

export interface HightLightText{
  selectedText:string;
  startIndex:number;
  bgColor : string;
  underline: string;
  contentType: string;
  isNotes: boolean;
  notes:string;
}

export interface HightLightTextData{
  questionId:string;
  hightLightText:HightLightText[];
}

export interface HighlighPopupPosition{
  top:string;
  left:string;
}

interface FullTestState {
  isDummy: boolean;
  currentTime: number;
  currentModuleName: string;
  readingModuleOneQuestions: ReadingModuleData[];
  readingModuleTwoQuestions: ReadingModuleData[]; // Question
  mathModuleOneQuestions: ReadingModuleData[];
  mathModuleTwoQuestions: ReadingModuleData[];
  count: number;
  isPopUp: boolean;
  testData: TestData[];
  testId: string;
  testName: string;
  userTestId: string;
  overallresult: any;
  completedTestData: any;
  currentClickedQuestionId: string;
  isTestCompleted: false;
  readingAndWritingModuleOneSpentTime: number;
  readingAndWritingModuleTwoSpentTime: number;
  mathModuleOneSpentTime: number;
  mathModuleTwoSpentTime: number;
  sidebarCollapse: boolean;
  optionStrikerButton: boolean;
  strikedOptionsData: StrikedOptionsData[];
  reviewPageClicked: boolean;
  isModuleCompleted: boolean;
  isHighlightNotes: boolean;
  highlightedContent: HightLightTextData[];
  highlightPopup:boolean;
  selectionBgColor:string;
  highlightPopupPosition: HighlighPopupPosition;
}


const initialState: FullTestState = {
  isDummy: true,
  currentTime: 1920,
  currentModuleName: "writingModule1",
  testData: [],
  completedTestData: [],
  testId: "",
  userTestId: "",
  testName: "",
  readingModuleOneQuestions: [],
  readingModuleTwoQuestions: [],
  mathModuleOneQuestions: [],
  mathModuleTwoQuestions: [],
  count: 0,
  isPopUp: false,
  overallresult: {},
  currentClickedQuestionId: "",
  isTestCompleted: false,
  readingAndWritingModuleOneSpentTime: 0,
  readingAndWritingModuleTwoSpentTime: 0,
  mathModuleOneSpentTime: 0,
  mathModuleTwoSpentTime: 0,
  sidebarCollapse: false,
  optionStrikerButton: false,
  strikedOptionsData: [],
  reviewPageClicked: false,
  isModuleCompleted: false,
  isHighlightNotes: false,
  highlightedContent : [],
  highlightPopup:false,
  selectionBgColor:"",
  highlightPopupPosition : {
    top:'',
    left:'',
  }
};

const startFullTest = createSlice({
    name:"startFullTest",
    initialState,
    reducers:{
        setIsModuleCompleted:(state,action) => {
            state.isModuleCompleted = action.payload
        },
        setIsSidebarCollapse:(state,action) => {
            state.sidebarCollapse = action.payload
        },
        setOptionStrikerButton:(state,action) =>{
            state.optionStrikerButton = action.payload;
        },
        setStrikedOptionsData:(state,action) =>{
            state.strikedOptionsData= action.payload;
        },
        setIsHighlightNotes:(state,action) =>{
          state.isHighlightNotes= action.payload;
        },
        setHighlightedContent:(state,action) =>{
          state.highlightedContent= action.payload;
        },
        setHighlightPopup:(state,action) =>{
          state.highlightPopup= action.payload;
        },
        setSelectionBgColor:(state,action) =>{
          state.selectionBgColor= action.payload;
        },
        setHighlightPopupPosition:(state,action) =>{
          state.highlightPopupPosition= action.payload;
        },
        isDummyRender:(state,action) => {
            state.isDummy = action.payload
        },
        changeCurrentModuleName:(state,action) => {
            state.currentModuleName = action.payload
            setItem("currentModuleName",action.payload)
        },
        setSpentTime :(state,action:PayloadAction<SetSpentTimePayload>) => {
            const { field, value } = action.payload;
            if (state.hasOwnProperty(field)) {
                state[field] = value;
              }
        },
        setTestId:(state,action) => {
            state.testId= action.payload;
        },
        setTestName:(state,action) => {
            state.testName =action.payload;
        },
        setUserTestId:(state,action) => {
            state.userTestId= action.payload;
        },
        setCurrentClickedQuestion:(state,action) => {
            console.log(action.payload,"action.payload")
            state.currentClickedQuestionId =action.payload
        },
        setReadingModuleOneQuestions:(state,action)=> {
            state.readingModuleOneQuestions = action.payload
        },
        setReadingModuleTwoQuestions:(state,action)=> {
            state.readingModuleTwoQuestions = action.payload
        },
        setMathModuleOneQuestions:(state,action) => {
            state.mathModuleOneQuestions= action.payload;
        },
        setMathModuleTwoQuestions:(state,action) => {
            state.mathModuleTwoQuestions= action.payload;
        },
        setReviewPageClicked:(state,action) => {
          state.reviewPageClicked = action.payload
        },
        setCount:(state,action) => {
            
            if(action.payload === "Increment"){
                state.count++
            }else if(action.payload === "decrement"){
                state.count--
            }else if(action.payload ){
                state.count = action.payload
            }else{
                state.count = 0
            }
          
        },
        setCurrentTime:(state,action) => {
            state.currentTime = action.payload
        },
        setIsPopUp:(state,action) => {
            state.isPopUp =action.payload
        },
        setOverallResultSlice:(state,action) =>{
                state.overallresult = action.payload
        },
        setTestCompleted:(state,action) => {
          state.isTestCompleted = action.payload
        },
        handleSelectedText: (state, action) => {
            const { selectedText, parentId, questionId, startIndex } = action.payload;
            const existingQuestionIndex = state.highlightedContent.findIndex(
              (item) => item.questionId === questionId
            );
          
            if (state.selectionBgColor === "") {
              state.selectionBgColor = "lightBlue";
            }
          
            if (existingQuestionIndex !== -1) {
              let questionHighlights = [...state.highlightedContent[existingQuestionIndex].hightLightText];
          
              const selectedTextIndex = questionHighlights.findIndex(
                (item) => item.selectedText === selectedText && item.startIndex === startIndex
              );
          
              if (selectedTextIndex === -1) {
                questionHighlights.push({
                  selectedText,
                  startIndex,
                  bgColor: state.selectionBgColor,
                  underline: 'none',
                  contentType: parentId || '',
                  isNotes: false,
                  notes: ''
                });
                state.highlightedContent[existingQuestionIndex].hightLightText = questionHighlights;
              } else {
                console.log("Text already highlighted at this position");
              }
            } else {
              state.highlightedContent.push({
                questionId,
                hightLightText: [{
                  selectedText,
                  startIndex, 
                  bgColor: state.selectionBgColor,
                  underline: 'none',
                  contentType: parentId || '',
                  isNotes: false,
                  notes: ''
                }],
              });
            }
        },
        handleDeleteSelectedSentence:(state,action) => {
          const {questionId,selectedText,startIndex} = action.payload
            let existingQuestionIndex = state.highlightedContent.findIndex(
              (item) => item.questionId === questionId
            );
        
            if (existingQuestionIndex !== -1) {
        
              let selectedIndex = state.highlightedContent[existingQuestionIndex].hightLightText.findIndex(
                (item) => item.selectedText === selectedText && item.startIndex === startIndex
              );
        
              if (selectedIndex !== -1) {
                state.highlightedContent[existingQuestionIndex].hightLightText.splice(selectedIndex, 1);
                state.highlightPopup = false; 
              }
            }

        }
    },
    extraReducers: builder => {
        builder.addMatcher(
           startTestApi.endpoints.getAllMockTests.matchFulfilled,
           (state: FullTestState, action: PayloadAction<{ data: { testData: TestData[] } }>) => {
            const data = action.payload?.data?.testData;
             state.testData = [...data];
           }
        );
        builder.addMatcher(
          startTestApi.endpoints.getProTests.matchFulfilled,
          (
            state: FullTestState,
            action: PayloadAction<{ data: { testData: TestData[] } }>
          ) => {
            const newTestData = action.payload?.data?.testData || [];
            const combinedTestData = [...state.testData, ...newTestData];
            const uniqueTests = combinedTestData.filter(
              (item, index, array) => {
                return (
                  array.findIndex(
                    (innerItem) => innerItem.testid === item.testid
                  ) === index
                );
              }
            );
            state.testData = uniqueTests;
          }
        );

        builder.addMatcher(
          startTestApi.endpoints.getAllCompletedTest.matchFulfilled,
          (
            state: FullTestState,
            action: PayloadAction<{
              data: { testData: TestData[]; questionData: any[] };
            }>
          ) => {
            state.completedTestData = action.payload?.data;
          }
        );
       }
       
      
})

export const {
  setReviewPageClicked,
  setIsSidebarCollapse,
  setOptionStrikerButton,
  setStrikedOptionsData,
  setIsHighlightNotes,
  setHighlightedContent,
  handleSelectedText,
  handleDeleteSelectedSentence,
  setHighlightPopup,
  setHighlightPopupPosition,
  setSelectionBgColor,
  isDummyRender,
  changeCurrentModuleName,
  setSpentTime,
  setTestId,
  setCurrentClickedQuestion,
  setTestCompleted,
  setUserTestId,
  setTestName,
  setReadingModuleOneQuestions,
  setReadingModuleTwoQuestions,
  setMathModuleOneQuestions,
  setMathModuleTwoQuestions,
  setCount,
  setCurrentTime,
  setIsPopUp,
  setOverallResultSlice,
  setIsModuleCompleted,
} = startFullTest.actions;
export default startFullTest.reducer;