import CrossOutToggle from "../../../assets/Images/CrossOutToggle.svg";
import { AssistiveTechnologyDescription } from "./AssistiveTechnologyDescription.jsx";
import { TextToSpeechDescription } from "./TextToSpeechDescription.jsx";
import ScreenMagnificationDescription from "./ScreenMagnificationDescription";
import ScreenReadersDescription from "./ScreenReadersDescription";
import SpeechToTextDescription from "./SpeechToTextDescription";
import ZoomAndMagnificationDescription from "./ZoomAndMagnificationDescription";
import MathJaxDescription from "./MathJaxDescription";
import LinkToreferencedContentDescription from "./LinkToReferencedContentDescription";
import RefreshableBrailleDisplaysDescription from "./RefreshableBrailleDisplaysDescription";
import InAppCalculatorDescription from "./InAppCalculatorDescription";
import TestTimersDescription from "./TestTimersDescription";
import HighlightsAndNotesDescription from "./HighlightsAndNotesDescription";
import SubmittingYourAnswersDescription from "./SubmittingYourAnswersDescription";
import RestartingTheAppDescription from "./RestartingTheAppDescription";
import NoPenaltyGuessingDescription from "./NoPenaltyGuessingDescription";
import QuestionMenuDescription from "./QuestionMenuDescription";
import MarkforReviewDescription from "./MarkForReviewDescription";
import OptionsEliminatorDescription from "./OptionsEliminatorDescription";
import LineReaderDescription from "./LineReaderdescription";

export type ShortcutsType = {
  id: string;
  action: string;
  keys: string;
};

export const shortcutsChromebook = [
  {
    id: "1",
    action: "Read items on a page",
    keys: "Search or Launcher + right arrow (or left arrow to move back)",
  },
  {
    id: "2",
    action: "Go to the next/previous line",
    keys: "Search or Launcher + up/down arrow",
  },
  {
    id: "3",
    action: "Start reading from the current location",
    keys: "Search or Launcher + R",
  },
  { id: "4", action: "Stop/pause reading", keys: "Ctrl" },
];

export const shortcutsWindows = [
  {
    id: "1",
    action: "Start or stop Narrator",
    keys: "Windows logo key + Ctrl + Enter",
  },
  { id: "2", action: "Exit Narrator", keys: "Narrator + Esc" },
  { id: "3", action: "Move to the next item", keys: "Narrator + right arrow" },
  {
    id: "4",
    action: "Move to the previous item",
    keys: "Narrator + left arrow",
  },
  {
    id: "5",
    action: "Start reading continuously",
    keys: "Narrator + down arrow or Narrator + R",
  },
  { id: "6", action: "Stop/pause reading", keys: "Ctrl" },
];

export const shortcutsMacos = [
  { id: "1", action: "Tum the screen reader on/off", keys: "Command + F5" },
  { id: "2", action: "Stop/pause reading", keys: "Ctrl" },
  {
    id: "3",
    action: "Read next/previous item",
    keys: "VO + right arrow/left arrow",
  },
  {
    id: "4",
    action: "Read the next focusable item (e.g., link, button)",
    keys: "Tab",
  },
  {
    id: "5",
    action: "Start reading continuously from this point on",
    keys: "VO + A",
  },
];

export const assistiveTechnologyItems = [
  {
    id: 0,
    name: "Assistive Technology",
    description: <AssistiveTechnologyDescription />,
  },
  {
    id: 1,
    name: "Text-to-Speech",
    description: <TextToSpeechDescription />,
  },
  { id: 2, name: "Speech-to-Text", description: <SpeechToTextDescription /> },
  {
    id: 3,
    name: "Screen Readers",
    description: <ScreenReadersDescription />,
  },
  {
    id: 4,
    name: "Zoom and Magnification",
    description: <ZoomAndMagnificationDescription />,
  },
  { id: 5, name: "MathJax", description: <MathJaxDescription /> },
  {
    id: 6,
    name: "Link to Referenced Content",
    description: <LinkToreferencedContentDescription />,
  },
  {
    id: 7,
    name: "Refreshable Braille Displays",
    description: <RefreshableBrailleDisplaysDescription />,
  },
];

export const helpItems = [
  {
    id: 0,
    name: "Zoom and Magnification",
    description: <ZoomAndMagnificationDescription />,
  },
  {
    id: 1,
    name: "In-App Calculator",
    description: <InAppCalculatorDescription />,
  },
  {
    id: 2,
    name: "Testing Timers",
    description: <TestTimersDescription />,
  },
  {
    id: 3,
    name: "Highlights & Notes",
    description: <HighlightsAndNotesDescription />,
  },
  {
    id: 4,
    name: "Line Reader",
    description: <LineReaderDescription/>,
  },
  {
    id: 5,
    name: "Options Eliminator",
    description: <OptionsEliminatorDescription />,
  },
  {
    id: 6,
    name: "Mark for Review",
    description: <MarkforReviewDescription />,
  },
  {
    id: 7,
    name: "Question Menu",
    description: <QuestionMenuDescription />,
  },
  {
    id: 8,
    name: "No Penalty for Guessing",
    description: <NoPenaltyGuessingDescription />,
  },
  {
    id: 9,
    name: "Restarting the App",
    description: <RestartingTheAppDescription />,
  },
  {
    id: 10,
    name: "Submitting Your Answers",
    description: <SubmittingYourAnswersDescription />,
  },
  {
    id: 11,
    name: "Screen Readers",
    description: <ScreenReadersDescription />,
  },
  {
    id: 12,
    name: "Screen Magnification",
    description: <ScreenMagnificationDescription />,
  },
];
