import {shortcutsWindows} from "./HelpAssistiveTechnologyDialogContent.tsx";
  
export const TextToSpeechDescription = () => (
    <>
    <div className =" my-12">
        <p className =" font-bold">Chromebook Select-to-Speak</p>
        
        <p className="my-6"> From the floating accessibility menu, select <span className="font-bold">Enable Select-to-Speak</span>.
        Once enabled, you can navigate the Bluebook app with the following
        guidance</p>
        <p className="my-4"> Press the <span className="font-bold">Search</span> or <span className="font-bold">Launcher</span> keys
        for shortcuts depending on the keyboard. Both keys work the same way.</p>
        <p>Option 1</p>
        <div className="px-8">
        <li>Press and hold the <span className="font-bold">Search Launcher</span> key.</li>
        <li>Drag the cursor over an area of text.</li>
        </div>
        <p>Option 2</p>
        <div className="px-8">
        <li>Highlight the text to be read with the mouse/trackpad.</li>
        <li> Press <span className="font-bold">Search </span>or <span className="font-bold">Launcher + S</span>.</li>
        </div>
        <p className="my-6">The Navigation Controller may appear, allowing you to navigate content
        blocks.</p>
        <p> Note: The kevboard can also be used with the Navigation Controller.
        </p>
        <div className="px-8">
        <li>Spacebar: Pause or Play speech</li>
        <li>Arrow keys: Navigate forward and backward through the content</li>
        </div>
        <p>To stop Select-to-Speak while it's reading, press
        <span className="font-bold">Ctrl</span> or the <span className="font-bold">Search/Launcher key</span>. You
        can also select <span className="font-bold">Stop</span> in the Navigation Controller
      </p>
    </div>

    <div className ="my-12">
        <p className="font-bold">Windows Narrator</p>
        <p className="my-6">To start or stop Narrator, press the Windows logo key + Ctrl + Enter
        together.</p>
        <p>Important: The Narrator key is a modifier key in the Narrator
        commands. Both the Caps Lock and Insert keys are the Narrator key by
        default. The Narrator key is referred to as "Narrator" in commands.
        </p>
        <p className="mt-1 text-center"><span className="font-bold">Basic keyboard navigation</span></p>
        
        <table className="min-w-full divide-y divide-gray-200 border border-lightGrey border-collapse">
          <thead className="border-collapse border border-lightGrey">
            <tr>
              <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                Action
              </th>
              <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                Keyboard Shortcut
              </th>
            </tr>
          </thead>
          <tbody>
            {shortcutsWindows.map((shortcut) => (
              <tr key={shortcut.id} className="border border-darkGrey">
                <td className="px-4 py-2 text-left ">
                  {shortcut.action}
                </td>
                <td className="px-4 py-2 border-l border-darkGrey">
                  {shortcut.keys}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>

    <div className ="my-12">
        <p className="font-bold">MacOS Spoken Content</p>
        <p className="mt-6">MacOS has a speech option that will speak the text when a student presses the default key combination recommended for use with the Bluebook application: <span className="font-bold">Option + Esc</span>. For all supported Mac operating systems (macOS 11 and higher):</p>
        <div className="px-8">
            <li>Go to the Apple menu and select <span className="font-bold">System Settings</span>.</li>
            <li>Select <span className="font-bold">Accessibility</span>.</li>
            <li>From the Accessibility screen, select <span className="font-bold">Spoken Content</span> from the left menu.</li>
            <li>Toggle ON the option <span className="font-bold">Speak Selection</span>.</li>
            
        </div>
        <p className="mt-6">To change Speak Selection preferences, select the Options/Info menu:</p>
        <div className="px-8">
            <li>Keyboard shortcut: To change the default keyboard shortcut (Option + Esc), press any other key combinations (e.g., Option + Tab). After completing this step, the text you select will be read aloud whenever you press the keyboard shortcut. Press the same key combination to stop the speech.</li>
            <li>Highlight content (select based on preference):
                <div className="px-8">
                    <li>None</li>
                    <li>Words</li>
                    <li>Sentences</li>
                    <li>Words and Sentences</li>
                </div>
            </li>
            <li>Word, Sentence Color, and Sentence Style can also be adjusted based on preferences.</li>
            <li>Show Controller: Select Always and the Controller will remain on the screen or select Automatically to
            show the Controller when selecting the keyboard shortcut (Option + Esc).</li>
            <li>Detected Languages: Toggle OFF</li>
        </div>
        <p>When all the preferences are set, click OK</p>
        <p className="mt-6">Toggle ON the option Speak Item under the pointer.</p>
        <div className="pt-8">
            <li>To change the preferences, select the Options/Info menu.
                <div className="px-8">
                <li>Speak Item under the pointer: Always (recommended)</li>
                <li>Speech Verbosity (Low, Medium, High)</li>
                <li>After Delay (slider from short to long)</li>
                </div>
            </li>  
            
        </div>

        <p>When all the preferences are set, click OK.</p>
        <p className="mt-6">Note: Depending on the version of MacOS, some accessibility features may not function as expected. College Board strongly encourages students to access practice tests on Bluebook with the accessibility features enabled before test day to ensure they work as expected.</p>
      </div>

      <div className="my-12">
        <p className="font-bold my-6">iPad Spoken Content</p>
        <p>An iPad can speak selected text or the entire screen. For all supported iOS devices:</p>
        <div className="px-8">
            <li>Tap the <span className="font-bold">Settings</span> icon and select <span className="font-bold">Accessibility</span>.</li>
            <li>From the Accessibility screen, select <span className="font-bold">Spoken Content</span></li>
            <li>Select <span className="font-bold">Speak Selection</span> and <span className="font-bold">Speak Screen</span>.</li>
            <li>Enable (toggle ON) <span className="font-bold">Speech Controller</span> and adjust controller actions for Long Press, Double Tap, and Idle
            Opacity</li>
            <li>Enable (toggle ON) <span className="font-bold">Highlight Content</span>, as preferred, and select settings for Words, Sentences, Words & Sentences. Make selections as needed for sentence highlighting and highlight colors.</li>
        </div>
        <p>Important: Ensure that <span className="font-bold">Detect Languages</span> is off.</p>
      </div>
    </>
  );

export default TextToSpeechDescription;
