import { useCallback, useEffect } from "react";
import CommonTestModuleLayout from "../ReUsableComponents/CommonTestModuleLayout";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setIsModuleCompleted,
  setIsPopUp,
  setReviewPageClicked,
  setSpentTime,
  setTestCompleted,
} from "../../redux/Slices/startFullTestSlice";

import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import { openDialog } from "../../redux/Slices/dialogSlice";
import { RootState } from "../../redux/Store/store";
import { getItem } from "../../utils/token";

const WriteTestModuleOne = () => {
  const dispatch = useDispatch();
  const { startTestSlice, resultSlice } = useSelector(selectFullTestState);
  const saveHandler = () => {
    // dispatch(openDialog("writingInstructions"));
  };

  const { readingModuleOneQuestions, count, reviewPageClicked } =
    startTestSlice;
  const currentTime = useSelector(
    (state: RootState) => state.root.startFullTest.currentTime
  );
  const { writingModuleOneAnswers } = resultSlice;

  const heading = useCallback(() => {
    return (
      <div className="flex flex-col">
        <div className="text-sm font-semibold pb-2">
          Section 1,Module 1: Reading and Writing
        </div>
        {/* <div className="cursor-pointer">Directions </div> */}
      </div>
    );
  }, []);
  const timer = () => {
    return <div>29:25</div>;
  };
  const exit = () => {
    return (
      <div>
        <CustomButton
          name="Save & Exit"
          onClickHandler={saveHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-full"
          border="border-[#333]"
          py="py-2"
          round="full"
        />
      </div>
    );
  };
  const children = () => {
    return (
      <div className="flex justify-center items-center">
        <div>Writing Test Module One Children Component </div>
        <div></div>
      </div>
    );
  };
  const textName = () => {
    return <div className="font-semibold"> {getItem("currentTestName")} </div>;
  };
  let moduleCompleteTimeout: NodeJS.Timeout | null = null;
  let writingModule2Timeout: NodeJS.Timeout | null = null;
  const nexxtClickHandler = () => {
    dispatch(setReviewPageClicked(false));
    dispatch(setIsPopUp(false));
    if (count > readingModuleOneQuestions.length - 1 || reviewPageClicked) {
      dispatch(
        setSpentTime({
          field: "readingAndWritingModuleOneSpentTime",
          value: +currentTime,
        })
      );
            dispatch(setIsModuleCompleted(true));
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      writingModule2Timeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("writingModule2"));
        dispatch(setCurrentTime(1920));
      }, 7000);
      dispatch(setCount(""));
    } else {
      dispatch(setCount("Increment"));
    }
  };

   useEffect(() => {
     // Clear timeouts on component unmount
     return () => {
       if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
       if (writingModule2Timeout) clearTimeout(writingModule2Timeout);
     };
   }, []);

  const next = () => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          text="text-sm"
          py="py-4 px-6"
          round="full"
          // hover="hover:bg-primaryColor"
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(changeCurrentModuleName("writingModule1"));
    // dispatch(openDialog("writingInstructions"));
    dispatch(setTestCompleted(false));
    dispatch(setIsModuleCompleted(false));
  }, []);

  useEffect(() => {
    if (currentTime <= 0) {
      moduleCompleteTimeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("moduleComplete"));
      }, 500);
      writingModule2Timeout = setTimeout(() => {
        dispatch(changeCurrentModuleName("writingModule2"));
        dispatch(setCurrentTime(1920));
      }, 7000);
      dispatch(setCount(""));
    }
  }, [currentTime, dispatch]);
  return (
    <CommonTestModuleLayout
      heading={heading}
      timer={timer}
      exit={exit}
      textName={textName}
      next={next}
      questionsList={readingModuleOneQuestions}
      moduleName={"writingModuleOneAnswers"}
      userAnswers={writingModuleOneAnswers}
    >
      {children}
    </CommonTestModuleLayout>
  );
};

export default WriteTestModuleOne;
