import { ReactNode, useEffect, Fragment, useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { FiUserPlus, FiUser } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { openDialog } from "../redux/Slices/dialogSlice";
import { setCreateAccount } from "../redux/Slices/loginSlice";
import { getItem } from "../utils/token";
import { jwtDecode } from "jwt-decode";
import { Popover, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { MdLogout } from "react-icons/md";
import Sidebar from "./SideBar";

interface Body {
  children: ReactNode;
}

const Layout = ({ children }: Body) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const logInHandler = () => {
    navigate("/login");
    dispatch(setCreateAccount(false));
  };
  const [isLocation, setIsLocation] = useState(false);

  const token = getItem("token");
  const isLogIn = getItem("isLogin");
  const decoded: any = token && jwtDecode(token);

  useEffect(() => {
    let timeRef: any = null;
    if (token) {
      const expiryTime = decoded.exp
        ? new Date(decoded.exp * 1000).getTime()
        : 0;
      const currentTime = new Date().getTime();
      const timeout = expiryTime - currentTime;
      const onExpiry = () => {
        toast.error("Your Session Expired");
        navigate("/");
        localStorage.clear();
      };
      if (timeout > 0) {
        timeRef = setTimeout(onExpiry, timeout);
      } else {
        onExpiry();
      }
      return () => {
        if (timeRef) clearTimeout(timeRef);
      };
    }
  }, [navigate, token]);

  console.log(location.pathname,'asdfg')

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home" || location.pathname === "/login") {
      localStorage.clear();
    }
  }, [location]);

  const profile = [
    {
      name: `Logout`,
      href: "/login",
    },
  ];

  useEffect(() => {
    const pathMatch = location.pathname.includes("/practices/") || location.pathname.includes("/categories/");
    setIsLocation(pathMatch);
  }, [location.pathname]);

  return (
    <div>
      <div className="flex">
        <Sidebar />
        <div className="flex-1 flex flex-col h-full">
          <header className="flex justify-between  items-center flex-shrink-0 h-20 px-6 w-full border border-b-[#e4e4e4]">
            <div className="flex ">
              <button className="font-bold text-primaryColor md:text-4xl ">
                S A T
              </button>
              {new Date(decoded?.proTill) > new Date() && (
                <span className=" h-1/4 px-3 py-1  text-xs font-extrabold rounded-full text-white bg-[#115F64] shadow-md">
                  PRO
                </span>
              )}
            </div>

            <div>
              {isLogIn ? (
                <div className="cursor-pointer">
                  <div className="w-full max-w-sm">
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={`
                            ${open ? "text-white" : "text-white/90"}
                            w-[2rem] outline-none h-[2rem]  font-bold flex justify-center items-center p-2 border rounded-full bg-primaryColor`}
                          >
                            <span>
                              <FiUser size={50} className="text-white p-4 " />
                            </span>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute pr-4 z-10 mt-3 w-[6.5rem] max-w-sm -translate-x-1/2 transform  lg:max-w-3xl">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                <div className="flex flex-col justify-center items-center py-2  bg-white  ">
                                  {profile.map((item) => (
                                    <a
                                      onClick={() => {
                                        localStorage.clear();
                                      }}
                                      key={item.name}
                                      href={item.href}
                                      className="  rounded-lg transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50 "
                                    >
                                      <p className="text-sm flex items-center gap-1 font-bold text-gray-900 w-full hover:bg-blue-500 hover:rounded-lg hover:text-white hover:p-2 p-2">
                                        {item.name === "Log Out" && (
                                          <MdLogout size={20} />
                                        )}{" "}
                                        {item.name}
                                      </p>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
              ) : (
                <button
                  className="font-bold text-primaryColor text-base cursor-pointer flex  "
                  onClick={logInHandler}
                >
                  <div className=" w-[2rem] h-[2rem] flex justify-center items-center">
                    <FiUserPlus size={30} />
                  </div>
                  <span className="flex justify-center pl-2 items-center">
                    Sign in
                  </span>
                </button>
              )}
            </div>
          </header>
          <main>
            <div className="flex-1 flex flex-col">{children}</div>
          </main>
        </div>
      </div>
      {!isLocation && (
        <footer className="bg-[#333] flex items-center w-full flex-shrink-0">
          <div className="md:px-6 px-6 pt-9 pb-11 max-w-none flex justify-between w-full  ">
            <div className="flex flex-col  lg:w-3/5 w-full  ">
              <div className="font-bold text-primaryColor text-4xl  max-w-40 mb-6">
                S A T
              </div>
              <p className="text-[#fff] text-[0.815rem] opacity-70 mb-4 leading-normal">
                SAT offers high-quality practice tests that are closely modeled
                after the actual tests. We strive to offer the best resources at
                the most affordable price.
              </p>
              <p className="text-[#fff] text-[0.815rem] opacity-70 mb-4 leading-normal">
                All trademarks, logos and brand names are the property of their
                respective owners. All company, product and service names used
                in this website are for identification purposes only. Use of
                these names, trademarks and brands does not imply endorsement.
              </p>
              <p className="text-[#fff] text-[0.75rem] opacity-70 mb-4">
                © 2024 All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default Layout;
