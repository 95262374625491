export const SubmittingYourAnswersDescription = () =>(
    <>
      <p>
        During testing, the app will save all your work. When the exam is
        over, your answers will be submitted automatically. If you're still
        working when time expires, your responses will be automatically saved
        and submitted. If your device is offline or your submission fails for
        any reason, you'll have time to reconnect and submit your work.
      </p>
    </>
  );
    
    export default SubmittingYourAnswersDescription;