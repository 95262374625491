import React, { useCallback, useEffect } from 'react'
import CustomButton from '../ReUsableComponents/CustomButton';
import CommonTestModuleLayout from '../ReUsableComponents/CommonTestModuleLayout';
import { changeCurrentModuleName, setCount, setCurrentTime, setIsModuleCompleted, setIsPopUp, setReviewPageClicked, setSpentTime } from '../../redux/Slices/startFullTestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../redux/Slices/dialogSlice';
import { selectFullTestState } from '../../redux/Selectors/selectorsUse';
import { next } from '../../utils/commonLogic';
import { RootState } from '../../redux/Store/store';
import { getItem } from '../../utils/token';

const WritingModuleTwo = () => {
    const dispatch = useDispatch();
    const {startTestSlice,resultSlice} = useSelector(selectFullTestState);
    const currentTime = useSelector((state:RootState) => state.root.startFullTest.currentTime);
    const { reviewPageClicked } = useSelector((state:RootState) => state.root.startFullTest);
    const saveHandler = () => {
      // dispatch(openDialog("writingInstructions"));
      };
    
      const heading = useCallback(() => {
        return (
          <div className="flex flex-col">
            <div className="text-sm font-semibold pb-2">
            Section 1,Module 2: Reading and Writing
            </div>
          </div>
        );
      },[]);
      const timer = () => {
        return <div>19:25</div>;
      };
      const exit = () => {
        return (
          <div>
            <CustomButton
              name="Save & Exit"
              onClickHandler={saveHandler}
              bg="bg-white"
              color="[#333]"
              font="font-bold"
              width="w-full"
              border="border-[#333]"
              py="py-2"
              round="full"
            />
          </div>
        );
      };
      const children = () => {
        return <div className="flex justify-center items-center">Writing Test Module TWO Children Component </div>;
      };
      const textName = () => {
        return <div className="font-semibold">  {getItem('currentTestName')} </div>;
      };
        let moduleCompleteTimeout: NodeJS.Timeout | null = null;
        let breakTimeTimeout: NodeJS.Timeout | null = null;
      const nexxtClickHandler = () => {
          dispatch(setReviewPageClicked(false));
        if (
          startTestSlice.count >
            startTestSlice.readingModuleTwoQuestions.length - 1 ||
          reviewPageClicked
        ) {
          dispatch(
            setSpentTime({
              field: "readingAndWritingModuleTwoSpentTime",
              value: +currentTime,
            })
          );
           dispatch(setIsModuleCompleted(true));
           moduleCompleteTimeout = setTimeout(() => {
             dispatch(changeCurrentModuleName("moduleComplete"));
           }, 500);
           breakTimeTimeout = setTimeout(() => {
             dispatch(changeCurrentModuleName("moduleBreakTime"));
             dispatch(setCurrentTime(600));
           }, 7000);
          dispatch(setCount(""));
        } else {
          dispatch(setCount("Increment"));
        }
        dispatch(setIsPopUp(false))
      };
    
       useEffect(() => {
         return () => {
           if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
           if (breakTimeTimeout) clearTimeout(breakTimeTimeout);
         };
       }, []);
      useEffect(() => {
        dispatch(changeCurrentModuleName("writingModule2"))
         // dispatch(openDialog("writingInstructions"));
        dispatch(setIsModuleCompleted(false));
      },[])

      useEffect(() => {
        if (currentTime <= 0) {
          moduleCompleteTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleComplete"));
          }, 500);
          breakTimeTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleBreakTime"));
            dispatch(setCurrentTime(600));
          }, 7000);
          dispatch(setCount(""));
        }
      }, [currentTime]);

      return (
        <CommonTestModuleLayout
          heading={heading}
          timer={timer}
          exit={exit}
          textName={textName}
          next={() =>next(nexxtClickHandler)}
          questionsList={startTestSlice.readingModuleTwoQuestions}
          moduleName={"writingModuleTwoAnswers"}
          userAnswers ={resultSlice.writingModuleTwoAnswers}
        >
          {children}
        </CommonTestModuleLayout>
      );
}

export default WritingModuleTwo