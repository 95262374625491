import {shortcutsWindows ,shortcutsChromebook ,shortcutsMacos} from "./HelpAssistiveTechnologyDialogContent.tsx";
  
export const ScreenReadersDescription = () => (
    <>
      <div className="my-2">
          <p className="font-bold">Chromebook ChromeVox</p>
          <p className="my-6">To enable ChromeVox, press <span className="font-bold">Ctrl + Alt + Z</span> or select it from the floating accessibility menu</p>
          <p className="my-6"><span className="font-bold">Note:</span> If ChromeVox turns off when you launch the Bluebook app or at any time, you can turn it back on with <span className="font-bold">Ctrl + Alt + Z</span>.</p>
          <p className="my-6">Test content can be navigated with the keyboard. Depending on your keyboard, you can press the <span className="font-bold">Search</span> or <span className="font-bold">Launcher</span> key for some shortcuts. Both keys work the same way</p>

          <div>

          <p className="mt-4 text-center"><span className="font-bold">Basic keyboard navigation:</span> Use the keyboard shortcuts if parts of the content are not initially reading.</p>
          <table className="min-w-full divide-y divide-gray-200 border border-lightGrey border-collapse">
            <thead className="border-collapse border border-lightGrey">
              <tr>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                  Action
                </th>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                  Keyboard Shortcut
                </th>
              </tr>
            </thead>
            <tbody>
              {shortcutsChromebook.map((shortcut) => (
                <tr key={shortcut.id} className="border border-darkGrey">
                  <td className="px-4 py-2 text-left ">
                    {shortcut.action}
                  </td>
                  <td className="px-4 py-2 border-l border-darkGrey">
                    {shortcut.keys}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
      </div>

      <div className="my-12">
          <p className="font-bold">Windows Narrator</p>
          <p className="my-6">To start or stop Narrator, press the Windows logo key + Ctrl + Enter together</p>
          <p className="my-6"><span className="font-bold">Important:</span> The Narrator key is a modifier key in the Narrator commands. Both the Caps Lock and Insert keys are the Narrator key by default. The Narrator key is referred to as "Narrator" in commands</p>
          <p className="mt-4 text-center"><span className="font-bold">Basic keyboard navigation</span></p>
          
          <table className="min-w-full divide-y divide-gray-200 border border-lightGrey border-collapse">
            <thead className="border-collapse border border-lightGrey">
              <tr>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                  Action
                </th>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                  Keyboard Shortcut
                </th>
              </tr>
            </thead>
            <tbody>
              {shortcutsWindows.map((shortcut) => (
                <tr key={shortcut.id} className="border border-darkGrey">
                  <td className="px-4 py-2 text-left ">
                    {shortcut.action}
                  </td>
                  <td className="px-4 py-2 border-l border-darkGrey">
                    {shortcut.keys}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>

      <div className="my-12">
          <p className="font-bold">MacOS VoiceOver</p>
          <p className="my-6">To start using VoiceOver:</p>
          <p className="my-6">Choose <span className="font-bold">Apple</span> menu {">"} <span className="font-bold">System Settings</span>, then click <span className="font-bold">Accessibility</span> in the sidebar. Click <span className="font-bold">VoiceOver</span> on.</p>
          <p className="my-6">Press <span className="font-bold">Command + F5</span> with the keyboard to turn VoiceOver on/off</p>
          <p className="mt-4 text-center"><span className="font-bold">Basic keyboard navigation:</span> VO is an abbreviation for VoiceOver keys<span className="font-bold">Control + Option</span> </p>
          
          <table className="min-w-full divide-y divide-gray-200 border border-lightGrey border-collapse">
            <thead className="border-collapse border border-lightGrey">
              <tr>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                  Action
                </th>
                <th className="px-4 py-2 text-left font-bold text-black border border-darkGrey">
                Keyboard Command
                </th>
              </tr>
            </thead>
            <tbody>
              {shortcutsMacos.map((shortcut) => (
                <tr key={shortcut.id} className="border border-darkGrey">
                  <td className="px-4 py-2 text-left">
                    {shortcut.action}
                  </td>
                  <td className="px-4 py-2 border-l border-darkGrey">
                    {shortcut.keys}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>

      <div className="my-12">
          <p className="font-bold">iPad VoiceOver</p>
          <p className="my-6">To start using VoiceOver: Go to <span className="font-bold">Settings {">"} Accessibility VoiceOver</span>, then on or off</p>
          <p><span className="font-bold">iPad Only:</span> Navigating Multiple-Choice Answer Options</p>
          <div className="px-8">
              <li>Keyboard-only users can navigate to a radio button option with the <span className="font-bold">Tab</span>key.</li>
              <li>More radio button options can be accessed with the <span className="font-bold">Tab</span>key and the <span className="font-bold">spacebar</span> to make an explicit selection.</li>
              <li>Alternatively, keyboard-only users can navigate through multiple-choice options with <span className="font-bold">Option + arrow</span> keys or <span className="font-bold">Control+arrow </span>keys.</li>
          </div>   
      </div>
    </>
  );
    
    export default ScreenReadersDescription;