import { useSelector } from "react-redux";
import { RootState } from "../redux/Store/store";
import WriteTestModuleOne from "../components/Exam/WriteTestModuleOne";
import WritingModuleTwo from "../components/Exam/WritingModuleTwo";
import MathModuleOne from "../components/Exam/MathModuleOne";
import MathModuleTwo from "../components/Exam/MathModuleTwo";
import ModuleBreakTime from "../components/Exam/ModuleBreakTime";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ModuleCompletionAlert from "../components/Exam/ModuleCompletionAlert";



const FullTest = () => {
  const navigate = useNavigate()
  let currentModuleName =useSelector((state:RootState) => state.root.startFullTest.currentModuleName);
   
  const location = useLocation();

  useEffect(() => {
    const handleBackButton = (event:any) => {
      const confirmBack = window.confirm(
        "Are you sure you want to leave this page?"
      );
      if (!confirmBack) {
        event.preventDefault();
      } else {
        navigate(-1);
      }
    };

    window.history.pushState(null, document.title, location.pathname);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [location, navigate]);

  return (
    <div>
      {currentModuleName === "writingModule1" && <WriteTestModuleOne />}
      {currentModuleName === "moduleComplete" && <ModuleCompletionAlert />}
      {currentModuleName === "writingModule2" && <WritingModuleTwo />}
      {currentModuleName === "moduleBreakTime" && <ModuleBreakTime />}
      {currentModuleName === "mathModule1" && <MathModuleOne />}
      {currentModuleName === "mathModule2" && <MathModuleTwo />}
      {!currentModuleName && <div className="h-screen flex justify-center items-center">
        <div className="flex flex-col">
        <h3 className="flex justify-center text-primaryColor my-2">Test Not Yet Started</h3>
      <button className='text-white bg-primaryColor px-4 py-2 rounded-lg flex justify-center items-center m-auto' onClick={() => navigate('/practices')}>
        Home</button>
        </div>
        </div>}
    </div>
  );
};

export default FullTest;
