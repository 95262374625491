import React from "react";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import CustomButton from "../ReUsableComponents/CustomButton";
import MathTable from "../../assets/Images/math_instructions.png";

const ModuleTwoInstructions = () => {
  const dispatch = useDispatch();
  const closeDialogHandler = () => {
    dispatch(closeDialog());
  };

  return (
    <div className="flex flex-col overflow-y-auto h-[50rem]">
      <div className="flex flex-col leading-6  mx-8 py-6 text-lg whitespace-pre-line opacity-80">
        <p>
          The questions in this section address a number of important math
          skills.
        </p>
        <p className="py-3">
          Use of a calculator is permitted for all questions. A reference sheet,
          calculator, and these directions can be accessed throughout the test.
        </p>
        <p>Unless otherwise indicated:</p>
        <p className="pl-16">
          <ul>
            <li> All variables and expressions represent real numbers.</li>
            <li> Figures provided are drawn to scale.</li>
            <li> All figures lie in a plane.</li>
            <li>
              The domain of a given function f is the set of all real numbers
              for which is a real number.
            </li>
          </ul>
        </p>
        <p className="py-3">
          For <span className="font-semibold">multiple-choice questions</span>,
          solve each problem and choose the correct answer from the choices
          provided, Each multiple-choice question has a single correct answer.
        </p>
        <p>
         <span> For&nbsp;</span>
          <span className="font-semibold">
            student-produced response questions
          </span>
          <span>, solve each problem and enter your answer as described below.</span>
        </p>
        <p className="pl-16">
          <ul>
            <li>
              {" "}
              If you find more than one correct answer, enter only one answer.
            </li>
            <li>
              You can enter up to 5 characters for a positive answer and up to 6
              characters (including the negative sign) for a negative answer.
            </li>
            <li>
              If your answer is a fraction that doesn't fit in the provided
              space, enter the decimal equivalent.
            </li>
            <li>
              If your answer is a decimal that doesn't fit in the provided
              space, enter it by truncating or rounding at the fourth digit.
            </li>
            <li>
              If your answer is a mixed number (such as 3[1/2]), enter it as an
              improper fraction (7/2) or its decimal equivalent (3.5).
            </li>
            <li>
              Don't enter symbols such as a percent sign, comma, or dollar sign.
            </li>
          </ul>
        </p>
        <div className="w-auto">
          <img src={MathTable} alt="mathTable" />
        </div>
      </div>
      <div className="flex justify-center">
        <CustomButton
          name="Close"
          onClickHandler={closeDialogHandler}
          bg="bg-white"
          color="[#333]"
          font="font-bold"
          width="w-24"
          border="border-[#333]"
          py="py-4"
          round="full"
        />
      </div>
    </div>
  );
};

export default ModuleTwoInstructions;
