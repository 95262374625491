import { useState } from "react";

// Email regex pattern
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// US Phone number regex pattern
const usPhoneRegex = /^(\+1[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;

// Custom hook for email validation
export const useEmailValidation = () => {
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email: string) => {
    if (!emailRegex.test(email.trim()) && email) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  return { emailError, validateEmail };
};

// Custom hook for phone validation
export const usePhoneValidation = () => {
  const [phoneError, setPhoneError] = useState("");

  const validatePhone = (phone: string) => {
    if (phone && !usPhoneRegex.test(phone)) {
      setPhoneError("Please enter a valid US phone number.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  return { phoneError, validatePhone };
};

export const usePasswordValidation = () => {
  const [passwordError, setPasswordError] = useState("");

  const validatePassword = (password: string) => {
    if (password.trim()) {
      console.log(password, "password");
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const uppercaseRegex = /[A-Z]/;
      if (
        password.trim().length < 8 ||
        !specialCharRegex.test(password) ||
        !uppercaseRegex.test(password)
      ) {
        setPasswordError(
          "Password must be at least 8 characters long, contain at least one special character, and one uppercase letter."
        );
        return false;
      } else {
        console.log("password is empty");
        setPasswordError("");
        return true;
      }
    }
  };

  return { passwordError, validatePassword, setPasswordError };
};

export const formatPhoneNumberToInternational = (phoneNumber: string) => {
  // Remove non-numeric characters
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Prepend '+1' for U.S. numbers
  const internationalNumber = `${cleanedNumber}`;

  return internationalNumber;
}

export const convertToUSANumber = (internationalNumber: string) => {
  // Remove the "+1" country code if it exists
  const cleanedNumber = internationalNumber?.replace(/^\+1/, "");

  // Check if the number is exactly 10 digits
  if (cleanedNumber?.length === 10) {
    const areaCode = cleanedNumber.slice(0, 3);
    const firstPart = cleanedNumber.slice(3, 6);
    const secondPart = cleanedNumber.slice(6);

    console.log(
      `(${areaCode}) ${firstPart}-${secondPart}`,
      "convertToUSANumber"
    );

    // Format the number in the desired USA format
    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Return an error or original number if it doesn't match the expected format
  return "";
}

