import React from "react";
import { IoWarningOutline } from "react-icons/io5";

type CustomTextFieldProps = {
  type: string;
  label?: string;
  placeholder: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | null;
  name: string;
  mandatory?: boolean;
  ref?: any;
  className?: string;
  isError?: boolean;
  containerClass?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
};

const CustomTextField = ({
  type,
  label,
  placeholder,
  onChangeHandler,
  value = "",
  name,
  mandatory,
  ref,
  className,
  isError,
  containerClass,
  onBlur,
  disabled,
  onPaste,
}: CustomTextFieldProps) => {
  const handleFocus = (e: any) => {
    e.target.removeAttribute("readonly");
  };

  return (
    <div className={`${containerClass} w-full relative`}>
      {label && (
        <label className="lg:text-sm md:text-[0.75rem] text-[0.75rem] mb-[5px] text-primaryColor">
          {label}
          {mandatory && (
            <span className="text-red-600 text-base font-normal">*</span>
          )}
        </label>
      )}
      <input
        style={{ boxShadow: "0px 4px 8px 0px #1C252C14" }}
        className={`${className} focus:outline-none font-medium text-base border h-[47px] py-2 ${
          (name === "studentPhoneNumber" || name === "parentPhoneNumber") &&
          value
            ? "px-[44px]"
            : "px-[23px]"
        } ${
          disabled &&
          "cursor-not-allowed !bg-[#E1E1E1] opacity-80 !text-[#646464]"
        } md:-pb-[1rem] -pb-[0.5rem] rounded-xl ${
          value && !disabled ? "!bg-white" : "bg-[#F5F9FF]"
        } ${
          isError
            ? "!border-[#D00000] my-input-danger text-[#D00000]"
            : "my-input border-[#E3E5E9] text-[#202020] "
        } `}
        type={type}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value={value ?? ""}
        name={name}
        ref={ref}
        autoComplete="rutjfkde"
        onBlur={onBlur}
        disabled={disabled}
        onPaste={onPaste}
      />
      {(name === "studentPhoneNumber" || name === "parentPhoneNumber") && (
        <span
          className={`absolute ${
            value ? "text-[#202020] pl-[20px]" : "text-[#646464] hidden"
          } ${
            isError && "text-[#D00000]"
          } left-1 top-1/2 transform -translate-y-1/2`}
        >
          +1
        </span>
      )}
      {isError && name !== "studentPassword" && name !== "parentPassword" && (
        <IoWarningOutline className="absolute right-5 top-1/2 transform -translate-y-1/2 text-[#D00000] text-[1.5rem]" />
      )}
    </div>
  );
};

export default CustomTextField;
