import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Answers{
    qId:string,
    userAnswer:string|undefined,
    userOption:string
}

interface ResultSlice{
    writingModuleOneAnswers:Answers[],
    writingModuleTwoAnswers:Answers[],
    mathsModuleOneAnswers:Answers[],
    mathsModuleTwoAnswers:Answers[],
}

const initialState:ResultSlice ={
    writingModuleOneAnswers:[],
    writingModuleTwoAnswers:[],
    mathsModuleOneAnswers:[],
    mathsModuleTwoAnswers:[],
}

interface Payload {
    module: string;
    payload: Answers; // Changed from Answers[] to Answers

}


const resultSlice = createSlice({
    name:"resultsStore",
    initialState,
    reducers:{
       setAnswers:(state,action:PayloadAction<Payload>) => {
        const {module,payload} = action.payload;
        const indexOfExistingQuestion= state[module as keyof ResultSlice].findIndex((qn:Answers) => qn.qId === payload.qId);
        if(indexOfExistingQuestion !== -1){
            state[module as keyof ResultSlice][indexOfExistingQuestion].userAnswer= payload.userAnswer
            state[module as keyof ResultSlice][indexOfExistingQuestion].userOption= payload.userOption
        }else{
            state[module as keyof ResultSlice].push(payload);
        }

       } ,
      clearAnswers:(state) => {
        state.mathsModuleOneAnswers =[]
        state.mathsModuleTwoAnswers =[]
        state.writingModuleOneAnswers=[]
        state.writingModuleTwoAnswers=[]
      },
      retrieveReadingModuleOneAnswers:(state,action) => {
        state.writingModuleOneAnswers=action.payload
      },
      retrieveReadingModuleTwoAnswers:(state,action) => {
        state.writingModuleTwoAnswers=action.payload
      },
      retrieveMathsModuleOneAnswers:(state,action) => {
        state.mathsModuleOneAnswers=action.payload
      },
      retrieveMathsModuleTwoAnswers:(state,action) => {
        state.mathsModuleTwoAnswers=action.payload
      }

    }
})
export const {setAnswers,clearAnswers,retrieveReadingModuleOneAnswers,retrieveReadingModuleTwoAnswers,retrieveMathsModuleOneAnswers,retrieveMathsModuleTwoAnswers} = resultSlice.actions;
export default  resultSlice.reducer;