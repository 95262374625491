import React, { useCallback, useEffect } from 'react'
import CustomButton from '../ReUsableComponents/CustomButton';
import CommonTestModuleLayout from '../ReUsableComponents/CommonTestModuleLayout';
import { changeCurrentModuleName, setCount, setCurrentTime, setIsModuleCompleted, setIsPopUp, setReviewPageClicked, setSpentTime } from '../../redux/Slices/startFullTestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../redux/Slices/dialogSlice';
import { RootState } from '../../redux/Store/store';
import { next } from '../../utils/commonLogic';
import { getItem } from '../../utils/token';

const MathModuleOne = () => {
    const dispatch = useDispatch();
    const currentTime = useSelector((state:RootState) => state.root.startFullTest.currentTime)
    const {mathModuleOneQuestions,count,mathsModuleOneAnswers,reviewPageClicked} = useSelector((state:RootState) => ({
      mathModuleOneQuestions:state.root.startFullTest.mathModuleOneQuestions,
      count:state.root.startFullTest.count,
      mathsModuleOneAnswers:state.root.resultsSlice.mathsModuleOneAnswers,
      reviewPageClicked:state.root.startFullTest.reviewPageClicked
    }))

    const saveHandler = () => {
        // dispatch(openDialog("mathsInstructions"));
      };
    
      const heading = useCallback(() => {
        return (
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              Section 2,Module 1: Math
            </div>
            {/* <div className="cursor-pointer">Directions </div> */}
          </div>
        );
      },[]);
      const timer = () => {
        return <div>9:25</div>;
      };
      const exit = () => {
        return (
          <div>
            <CustomButton
              name="Save & Exit"
              onClickHandler={saveHandler}
              bg="bg-white"
              color="[#333]"
              font="font-bold"
              width="w-full"
              border="border-[#333]"
              py="py-2"
              round="full"
            />
          </div>
        );
      };
      const children = () => {
        return <div className="flex justify-center items-center">Maths Module ONE Children Component </div>;
      };
      const textName = () => {
        return <div className="font-semibold">  {getItem('currentTestName')} </div>;
      };
      let moduleCompleteTimeout: NodeJS.Timeout | null = null;
      let mathModuleTimeOut: NodeJS.Timeout | null = null;
      const nextClickHandler = () => {
        dispatch(setReviewPageClicked(false));
        if (count > mathModuleOneQuestions.length - 1 || reviewPageClicked) {
        dispatch(setIsModuleCompleted(true));
          moduleCompleteTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleComplete"));
          }, 500);
          mathModuleTimeOut = setTimeout(() => {
            dispatch(changeCurrentModuleName("mathModule2"));
            dispatch(setCurrentTime(2100));
          }, 7000);
          dispatch(
            setSpentTime({
              field: "mathModuleOneSpentTime",
              value: +currentTime,
            })
          );
          dispatch(setCount(""));
        } else {
          dispatch(setCount("Increment"));
        }
        dispatch(setIsPopUp(false));
      };
    
       useEffect(() => {
         return () => {
           if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
           if (mathModuleTimeOut) clearTimeout(mathModuleTimeOut);
         };
       }, []);
  
      useEffect(() => {
        dispatch(changeCurrentModuleName("mathModule1"))
      // dispatch(openDialog("mathsInstructions"));
        dispatch(setIsModuleCompleted(false));
      },[])

       useEffect(() => {
         if (currentTime <= 0) {
           moduleCompleteTimeout = setTimeout(() => {
             dispatch(changeCurrentModuleName("moduleComplete"));
           }, 500);
           mathModuleTimeOut = setTimeout(() => {
             dispatch(changeCurrentModuleName("mathModule2"));
             dispatch(setCurrentTime(2100));
           }, 7000);
           dispatch(setCount(""));
         }
       }, [currentTime]);

      return (
        <CommonTestModuleLayout
          heading={heading}
          timer={timer}
          exit={exit}
          textName={textName}
          next={() => next(nextClickHandler)}
          questionsList={mathModuleOneQuestions}
          moduleName={"mathsModuleOneAnswers"}
          userAnswers={mathsModuleOneAnswers}
        >
          {children}
        </CommonTestModuleLayout>
      );
}

export default MathModuleOne