import CrossOutToggle from "../../../assets/Images/CrossOutToggle.svg";
export const OptionsEliminatorDescription = () => (
    <>
      <p>
        <span className="font-bold">
          <img
            className="inline-flex items-center flex-wrap"
            src={CrossOutToggle}
          />
          Option Eliminator:
        </span>
        On any multiple-choice question, you can cross out answer options you
        think are wrong. Above the answer, click the <span className="font-bold">ABC</span> button, then you'll
        see a button next to each answer option, allowing you to cross it out.
        You can always undo this action.
      </p>
    </>
  );
    
    export default OptionsEliminatorDescription;