import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import QuestionsOverView from "./QuestionsOverView";
import { StrikedOptionsData } from "../../redux/Slices/startFullTestSlice";

interface Answers {
  qId: string;
  userAnswer: string | undefined;
  userOption: string;
}

interface Answer {
  isActive: boolean;
  answerOption: string;
  isCorrectAnswer: boolean;
  correctAnswer: string;
  answerPlaceHolder: string;
}

interface ReadingModuleData {
  _id: string;
  isActive: boolean;
  description: string;
  question: string;
  explanation: string;
  moduleId: string;
  sectionId: string;
  answers: Answer[];
  questionTypeId: string;
  more: any; // You might want to replace 'any' with a more specific type
  additionalProps: any;
}

interface PopupProps {
  onClose: () => void;
  questionsList: ReadingModuleData[];
  userAnswers: Answers[];
  heading: () => React.ReactNode;
  count:number;
  isPopUp:boolean;
  strikedOutOptions: StrikedOptionsData[];
}

const PopUp = React.memo(({ onClose, questionsList, userAnswers, heading,count,isPopUp,strikedOutOptions }: PopupProps) => {

  return (
    <>
      {isPopUp && (
        <div className="absolute bottom-[160%] min-w-[40rem] bg-white text-[#272727] border border-[#e4e4e4] z-50 rounded-lg before:absolute before:-top-3 before:left-1/2 before:-translate-x-1/2 before:border-[10px] before:border-transparent before:border-b-white">
          <div className="mx-6 flex py-5 relative items-center">
            <div className="font-semibold mx-auto flex"><span className="mr-1">{heading()}</span><span className="py-1">Questions</span></div>
            <button
              className="text-gray-600 hover:text-gray-800 absolute right-0"
              onClick={onClose}
            >
              <IoCloseOutline size={24} />
            </button>
          </div>
          <QuestionsOverView
            questionsList={questionsList}
            userAnswers={userAnswers}
            count={count}
            navigation={true}
            heading={heading}
            strikedOutOptions={strikedOutOptions}
          />
        </div>
      )}
    </>
  );
},(prevProps,nextProps) => {
    return (
        prevProps.onClose === nextProps.onClose &&
        prevProps.questionsList === nextProps.questionsList &&
        prevProps.userAnswers === nextProps.userAnswers &&
        prevProps.heading() === nextProps.heading() &&
        prevProps.count === nextProps.count &&
        prevProps.isPopUp === nextProps.isPopUp
      );
});

export default PopUp;
