import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startTestApi } from "../API/StartTest/startTestApi";

interface Test {
    testId: string;
    name: string;
    sectionType: string;
    questionContentDomain: string;
    questionContentType: string;
    questionCount: number;
    testType: string;
    isFree: boolean;
    userTestState: any; // Consider defining a more specific type if possible
    active: boolean;
}

interface QuestionBank{
    isQuestionBanksEnabled:boolean;
    readingQuestionBank:Test[];
    mathQuestionBank:Test[];
    questionBankId:string;
    currentQuestionBank:any;
    counter:any;
    isStarted:boolean
}

interface GetAllQuestionBank {
    isQuestionBanksEnabled:boolean;
    testData :Test[]
}

const initialState:QuestionBank ={
    isQuestionBanksEnabled:false,
    readingQuestionBank:[],
    mathQuestionBank:[],
    questionBankId:'',
    currentQuestionBank:{},
    counter:0,
    isStarted:false
}

const questionBank = createSlice({
        name:"questionBank",
        initialState,
        reducers:{
            setQuestionBankId: (state,action) =>{
                state.questionBankId = action.payload
            },
            setCounter:(state,action) => {
                state.counter = action.payload
            },
            setIsStarted:(state,action) => {
              state.isStarted = action.payload
            }
        },
        extraReducers: (builder) => {
            builder.addMatcher(
              startTestApi.endpoints.getQuestionBankList.matchFulfilled,
              (state, action: PayloadAction<{ data: GetAllQuestionBank }>) => {
                state.isQuestionBanksEnabled = action.payload?.data.isQuestionBanksEnabled;
                state.readingQuestionBank = action.payload?.data.testData.filter(
                  (item) => item.sectionType === "READING_AND_WRITING"
                );
                state.mathQuestionBank = action.payload?.data.testData.filter(
                  (item) => item.sectionType === "MATHEMATICS"
                );
              }
            );
            builder.addMatcher(
              startTestApi.endpoints.getQuestionInQuestionBank.matchFulfilled,
              (state, action: PayloadAction<{ data: any }>) => {
                // Make sure this console statement isn't the problem.
                console.log(action.payload?.data, "DATATFYGBHUKMOLNJBHGVY");
                state.currentQuestionBank = action.payload.data?.testData;
                if(action?.payload?.data?.testData?.userTestState?.currentQId <0){
                  state.counter = 0
                }else{
                 if(state.isStarted) {
                  state.counter = action.payload.data?.testData?.userTestState?.currentQId 
                 }else{
                  state.counter =0
                 }
                  
                }
              }
            );
          }
          
});

export const {setCounter,setIsStarted} = questionBank.actions

export default questionBank.reducer