import React from 'react'

const MathsModuleDirections = () => {
    let instructions = [
        "If you find more than one correct answer, enter only one answer.",
        "You can enter up to 5 characters for a positive answer and up to 6 characters (including the negative sign) for a negative answer.",
        "If your answer is a fraction that doesn't fit in the provided space, enter the decimal equivalent.",
        "If your answer is a decimal that doesn't fit in the provided space, enter it by truncating or rounding at the fourth digit.",
        "If your answer is a mixed number (such as 3 1/2 ), enter it as an improper fraction (7/2) or its decimal equivalent (3.5).",
        "Don't enter symbols such as a percent sign, comma, or dollar sign"
      ];
  return (
    <div >
    <div className="text-xl font-semibold w-1/2" >Student-produced response directions</div>
    <ul className="list-disc ml-4 mt-2">
    {instructions.map((item,index) => 
      <li className="" key={item}>{item}</li>
    )}
    <img src="https://blueprep.com/images/math.png" alt="instro"/>
    </ul>
   
  </div>
  )
}

export default MathsModuleDirections