import React from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import { useNavigate } from "react-router-dom";
import { selectFullTestState } from "../../redux/Selectors/selectorsUse";
import {
  useGetAllMockTestsQuery,
  useLazyGetProTestsQuery,
  useSaveAndExitTestMutation,
} from "../../redux/API/StartTest/startTestApi";
import Loader from "../ReUsableComponents/Loader";
import { getItem } from "../../utils/token";
import { jwtDecode } from "jwt-decode";

const SaveAndExit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { startTestSlice, resultSlice, loadingSlice } =
    useSelector(selectFullTestState);
  const [saveAndExitTest] = useSaveAndExitTestMutation({});
  const { refetch } = useGetAllMockTestsQuery({});
  const [getProTests] = useLazyGetProTestsQuery({})

  const continueTest = () => {
    dispatch(closeDialog());
  };

  const exitTest = async () => {
    let savePayload = {
      userTestId: startTestSlice.userTestId,
      testId: startTestSlice.testId,
      questionData: null,
      answerData: {
        Mathematics: {
          Module1: resultSlice.mathsModuleOneAnswers,
          Module2: resultSlice.mathsModuleTwoAnswers,
        },
        ReadingAndWriting: {
          Module1: resultSlice.writingModuleOneAnswers,
          Module2: resultSlice.writingModuleTwoAnswers,
        },
      },
      additionalProps: {
        currentModuleName: startTestSlice.currentModuleName,
        count: startTestSlice.count,
        currentTime: startTestSlice.currentTime,
        strikedAndMarkedData : startTestSlice.strikedOptionsData,
        highlightedContent: startTestSlice.highlightedContent
      },
      testCompleted: false,
      overallTestResult:startTestSlice.overallresult
    };
    await saveAndExitTest(savePayload);
    refetch();
    const token = getItem("token");
    const decoded:any = token && jwtDecode(token);
    if(new Date(decoded?.proTill) > new Date()){
      getProTests({})
    }
    dispatch(closeDialog());
    navigate("/practices");
    //dispatch(clearAnswers())
  };

  return (
    <div className="flex flex-col">
      <div className="text-[#202020]">
        <p className="text-2xl mb-5">Do You Want to Exit this Practice Test?</p>
        <p className="text-base">
          If you exit now, we'll save your progress and you can resume this
          practice test any time.
        </p>
      </div>
      {loadingSlice.isLoading ? (
        <div className="flex justify-center ">
          <Loader />
        </div>
      ) : (
        <div className="flex justify-end mt-8 items-center">
          <CustomButton
            name="Continue Practice Test"
            onClickHandler={continueTest}
            bg="bg-white"
            font="font-semibold"
            mx="mx-2"
            color="primaryColor"
            text="text-sm"
          />
          <CustomButton
            name="Save and Exit"
            onClickHandler={exitTest}
            bg="bg-[#FEDB00]"
            font="font-semibold"
            color="black"
            border="border border-[#272727]"
            round="full"
            text="text-sm"
            py="py-4 px-6"
          />
        </div>
      )}
    </div>
  );
};

export default SaveAndExit;
