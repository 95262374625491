import React, { useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { IoLocationOutline } from "react-icons/io5";
import { setCount, setReviewPageClicked, StrikedOptionsData, setIsPopUp } from '../../redux/Slices/startFullTestSlice';
import ReviewOnIcon from "../../assets/Images/ReviewOnButton.svg"
import { RootState } from '../../redux/Store/store';

interface Answers {
    qId: string;
    userAnswer: string | undefined;
    userOption: string;
  }

  interface Answer {
    isActive: boolean;
    answerOption: string;
    isCorrectAnswer: boolean;
    correctAnswer: string;
    answerPlaceHolder: string;
  }

interface ReadingModuleData {
    _id: string;
    isActive: boolean;
    description: string;
    question: string;
    explanation: string;
    moduleId: string;
    sectionId: string;
    answers: Answer[];
    questionTypeId: string;
    more: any; // You might want to replace 'any' with a more specific type
    additionalProps: any;
  }

interface QuestionOverView{
    questionsList: ReadingModuleData[];
    userAnswers:Answers[];
    count:number;
    navigation:boolean;
    heading: () => React.ReactNode;
    strikedOutOptions: StrikedOptionsData[];
}


const QuestionsOverView = ({questionsList,userAnswers,count, navigation,heading,strikedOutOptions}:QuestionOverView) => {
    const dispatch = useDispatch();
    const navigateCurrentQuestion = useCallback((index: number) => {
        dispatch(setCount(index))
      }, [dispatch]);
      const {reviewPageClicked} = useSelector((state:RootState) => state.root.startFullTest)

      console.log(count,questionsList?.length,'defrgty');

  return (
    <div>
      <div>
      <div className="flex justify-around px-6 py-4 border-y mx-10 mb-3 border-[#e4e4e4]">
        {navigation ? (
          <div className="flex items-center gap-3  cursor-text">
            <IoLocationOutline size={20} />
            Current
          </div>
        ) : (
          <div className="flex text-sm font-semibold">
            <span className="mr-1">{heading()}</span>
            <span>Questions</span>
          </div>
        )}
        <div className="flex items-center gap-3  cursor-text">
          <div className="w-4 h-4 mr-1 border border-dashed border-black"></div>
          Unanswered
        </div>
        <div className="flex items-center gap-3  cursor-text">
          <img src={ReviewOnIcon} alt="review-icon" className="w-[18px] h-[18px]" />
          For Review
        </div>
      </div>
      <div className="flex flex-wrap px-6 pt-[15px]">
        {questionsList?.map((question, index) => {
          let matchedQuestion = userAnswers.find(
            (item) => item.qId === question["_id"]
          );
          let ismarkedForReview = strikedOutOptions?.find(
            (option) => option.questionId === question["_id"]
          )?.markedForReview;
          return (
            <div key={question._id}>
              <button
                className={`flex w-8 font-bold h-8 justify-center relative items-center border mx-2 my-3 hover:bg-lightGrey ${
                  matchedQuestion?.userAnswer
                    ? "bg-primaryColor border text-white"
                    : "border-dashed text-primaryColor"
                } border-black`}
                onClick={() => {
                  dispatch(setReviewPageClicked(false));
                  dispatch(setIsPopUp(false));
                  navigateCurrentQuestion(index)
                }}
              >
                {ismarkedForReview && (
                  <div className="bg-white p-1 absolute -top-3 -right-3">
                    <img src={ReviewOnIcon} className="w-3 h-4" />
                  </div>
                )}
                <p className={count === index ? 'underline' : ''}>{index + 1}</p>
                {count === index && (
                  <div className="flex justify-center absolute -top-[70%] text-black">
                    <IoLocationOutline size={20} />
                  </div>
                )}
              </button>
            </div>
          );
        })}
      </div>
      {!reviewPageClicked && count !== questionsList.length &&
      <div className="mt-4 mb-6 flex items-center justify-center">
        <button className='flex justify-center px-6 py-4 font-inter bg-white text-primaryColor border border-primaryColor rounded-[49px] font-semibold hover:border-2' onClick={() => dispatch(setReviewPageClicked(true))}>
          Go to Review Page
        </button>
      </div>
      }
    </div>
    <span className='absolute inner-block border-[25px] left-[46%] top-full border-x-transparent border-b-0 border-white'>

    </span>
    </div>
  );
}

export default QuestionsOverView