export const ZoomAndMagnificationDescription = () => (
    <>
      <div className="my-2">
      <p className="font-bold my-4">Chromebook Zoom and Magnification</p>
      <p className="my-6">To zoom in/out, press <span className="font-bold">Control +/-</span>. To reset zoom,press <span className="font-bold">Control +0.</span></p>
      <p className="my-6">For touchscreen and touchpad, pinch in/out.</p>
      <p >To use the magnification feature, select the options from the floating
      accessibility menu:</p>
      <div className="px-4">
      
        <li>
          Full-screen magnifier (<span className="font-bold"></span>Search + Ctrl + M) —
          whole-screen magnification
        </li>
        <div className="px-6">
          <li>
            Adjust the full-screen magnifier from the floating
            <span className="font-bold">
              Accessibility menu {">"} Settings {">"} Display and
              magnification {">"} Full screen magnifier {">"}
            </span>
            select any of these options:
            <div className="px-7">
              <li>Move screen continuously as mouse moves</li>
              <li>Move screen keeping mouse at center of screen</li>
              <li>Move screen when mouse touches edge of screen (default)</li>
            </div>
          </li>
        </div>
        <li>
          Docked magnifier(<span className="font-bold">Search + Ctrl + D</span>) — split-screen
          magnification
          <div className="px-6">
            <li>
              To adjust zoom level, select the down arrow in the
              <span className="font-bold">Docked zoomlevel.</span>
            </li>
            <li>
              To change what's magnified, move your pointer or press
              <span className="font-bold">Tab.</span>
            </li>
          </div>
        </li>
      </div>
      </div>

      <div className="my-12">
      <p className="font-bold my-4">Windows Zoom and Magnifier</p>
      <p className="my-6">To zoom in/out, press <span className="font-bold">Control +/-</span>. To reset zoom,press <span className="font-bold">Control +0.</span></p>
      <p className="my-6">For touchscreen and touchpad, pinch in/out.</p>
      
      <p>To use Windows Magnifier</p>
          <div className="px-8">
              <li>Windows 10: Select <span className="font-bold">Start{">"} Settings {">"} Ease of Access {">"} Magnifier {">"} Turn on Magnifier</span>.</li>
              <li>Windows 11: Select <span className="font-bold">Start {">"} Settings {">"} Accessibility {">"} Vision: Magnifier {">"} Turn on Magnifier</span>.</li>
              <li>Or press the <span className="font-bold">Windows logo key+plus sign (+)</span>. To turn off the Magnifier, press the <span className="font-bold">Windows logo key + Esc</span>.</li>
          </div>            
      <p>Adjust the Magnifier view per your preference:</p>
      <div className="px-6">
          <li>Lens (<span className="font-bold">Ctrl+Alt+L</span>)</li>
          <li>Full screen (<span className="font-bold">Ctrl+Alt+F</span>)</li>
          <li>Docked (<span className="font-bold">Ctrl+Alt+D</span>)</li>
      </div>

      </div>
      <div className="my-12">
      <p className="font-bold my-4">MacOS Zoom</p>
      <p className="my-6">To zoom in/out, press <span className="font-bold">Control +/-</span>. To reset zoom,press <span className="font-bold">Control +0.</span></p>
      <p className="my-6">For touchscreen and touchpad, pinch in/out.</p>
      <p className="my-6">To customize how the zoom area appears, go to<span className="font-bold"> Apple menu {">"} System Settings {">"} Accessibility</span> and click <span className="font-bold">Zoom on the right (keyboard shortcut: Option+Command+8)</span>.</p>
      
      <p>In the Advanced Options, select the checkboxes for your zoom preference</p>
          <div className="px-8">
              <li>Use keyboard shortcuts to zoom</li>
              <li>Use trackpad gesture to zoom</li>
              <li>Use scroll gesture with modifier keys to zoom</li>
          </div>   
      <p>Select Zoom style options per student preference:</p>
          <div className="px-8">
              <li>Full screen</li>
              <li>Split screen</li>
              <li>Picture-in-picture</li>
          </div>          
      </div>

      <div className="my-12">
      <p className="font-bold my-4">iPad Zoom</p>
      <p className="my-6">Pinch to zoom in or zoom out.</p>
      <p className="my-6">For additional zoom options in the iPad, tap <span className="font-bold"> Settings {">"} Accessibility {">"} Zoom</span></p>
      <p>Adjust any of the following:</p>
          <div className="px-8">
              <li>Keyboard shortcuts: Control zoom using shortcuts on an external keyboard.</li>
              <li>Zoom controller: Turn the controller on, set controller actions, and adjust the color and opacity.</li>
              <li>Zoom region: Choose Pinned Zoom, Full Screen Zoom, or Window Zoom.</li>
              <li>Zoom filters: Choose None, Inverted, Grayscale, Grayscale Inverted, or Low Light.</li>
              <li>Maximum zoom level: Drag the slider to adjust the level.</li>
          </div>  
      </div>
    </>
  );
    
    export default ZoomAndMagnificationDescription;