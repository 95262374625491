export const speechToTextDescription = () => (<>

    <div className="my-6">
        <p className="font-bold">Chromebook Dictation</p>
        <p className="mt-6">Chromebook provides two options for using the dictation feature for items requiring text entry:</p>
        
        <div className="px-8">
            <li>Dictation: From the floating accessibility menu, select <span className="font-bold">Dictation</span>.</li>
            <li>Speak with the onscreen keyboard: From the floating accessibility menu select <span className="font-bold">Onscreen keyboard</span>. At
            the top of the onscreen keyboard, select <span className="font-bold">Speak</span> (microphone).
                <div className="px-6">
                    <li>You can move, float, dock, and resize the onscreen keyboard.</li>
                </div>
            </li>
            <p className="mt-4">Tap or select where you want to type (input field, free-response questions, essays, etc.). Press <span className="font-bold">Search+D</span> or <span className="font-bold">Launcher + D</span>.</p>
        </div>
    </div>

    <div className="mt-12">
        <p className="font-bold">Windows Speech</p>
        <p className="my-4">To use Windows Speech, follow the steps below for your operating system:</p>
        <p className="my-4">Windows 10: Select <span className="font-bold">Start {">"} Settings {">"} Ease of Access {">"} Speech {">"} Turn on Speech Recognition</span>.</p>
        <p className="my-4">Windows 11: Select <span className="font-bold">Start {">"} Settings {">"} Accessibility{">"} Speech {">"} Turn on Voice Access</span>.
            <li className="px-8">Keyboard shortcut: Press <span className="font-bold">Windows logo key+Ctrl+S</span> to tum on speech recognition.</li>
        </p>
        <p className="my-4">Tip: For Windows 10 or 11, to help with navigation and selecting elements in Bluebook, say "show numbers." Numbers will appear on the screen for every element/grid in the active window. Say an item's corresponding number to select it (e.g., "19, OK").</p>
    </div>

    <div className="mt-12">
    <p className="font-bold ">MacOS Voice Control</p>
        <p className="my-4">To use Voice Control on a Mac, go to <span className="font-bold">System Settings {">"} Accessibility{">"}</span> enable (turn on) <span className="font-bold">Voice Control</span>.</p>
        <p className="my-4">Tip: To help navigate and select elements in Bluebook, say "show numbers." Numbers will appear on the screen for every element/grid in the active window. Say an item's corresponding number to select it (e.g., "Click 19, OK").</p>
        <p className="my-4">Recommended: Learn and practice Voice Control commands in the interactive guide. Go to <span className="font-bold">Settings {">"} Accessibility {">"} Voice Control {">"} Voice Control Guide {">"} Open Guide</span>.</p>
    </div>

    <div className="mt-12">
    <p className="font-bold">iPad Voice Control</p>
        <p className="my-4">To start using Voice Control on an iPad, go to <span className="font-bold">Settings {">"} Accessibility{">"}</span> enable (turn on) <span className="font-bold">Voice Control</span>.</p>
        <p className="my-4">Tip: To help navigate and select elements in Bluebook, say "show numbers." Numbers will appear on the screen for every element/grid in the active window. Say an item's corresponding number to select it (e.g., "Click 19, OK").</p>
        <p className="my-4">Recommended: Learn and practice Voice Control commands in the interactive guide. Go to <span className="font-bold">Settings {">"} Accessibility {">"} Voice Control {">"} Open Voice Control Guide</span>.</p>
    </div>
    </> );
    
    export default speechToTextDescription;