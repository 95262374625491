import React, { useEffect } from "react";
import CustomButton from "../ReUsableComponents/CustomButton";
import {
  changeCurrentModuleName,
  setCurrentTime,
} from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import Timer from "../ReUsableComponents/Timer";
import { getItem } from "../../utils/token";

const ModuleBreakTime = () => {
  const dispatch = useDispatch();
  const currentTime = useSelector(
    (state: RootState) => state.root.startFullTest.currentTime
  );

  useEffect(() => {
    dispatch(changeCurrentModuleName("moduleBreakTime"));
  }, []);

  // useEffect(() => {
  //   if (currentTime <= 0) {
  //     dispatch(setCurrentTime(2100));
  //     dispatch(changeCurrentModuleName("mathModule1"));
  //   }
  // }, [currentTime]);
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-[#272727]">
      {currentTime <= 0 ? (
        <div className="flex flex-col justify-center items-center">
          <p className="text-2xl text-white font-bold text-center pb-[15px]">
            Resume Testing Time
          </p>
          <p className="text-base text-white font-normal pb-[30px]">
            Your testing timer has not started counting down yet{" "}
          </p>
          <CustomButton
            text="text-sm"
            name="Resume Testing"
            onClickHandler={() => {
              dispatch(changeCurrentModuleName("mathModule1"));
              dispatch(setCurrentTime(2100));
            }}
            font="font-semibold"
            py="py-4"
            round="full"
            hover="hover:bg-black"
            bg="bg-[#FEDB00]"
            color="black"
          />
        </div>
      ) : (
        <>
          <div className="flex-grow flex py-4">
            <div className="w-1/2  font-bold text-center flex flex-col justify-center items-center  rounded-lg">
              <div className="flex flex-col border-2 border-[#e4e4e4] rounded-2xl p-6 w-1/2">
                <div className="text-base text-white pb-4">
                  Remaining Break Time
                </div>
                <div className="text-[64px] text-white">
                  <Timer />
                </div>
              </div>
              <div className="mt-8">
                <CustomButton
                  text="text-sm"
                  name="Resume Testing"
                  onClickHandler={() => {
                    dispatch(changeCurrentModuleName("mathModule1"));
                    dispatch(setCurrentTime(2100));
                  }}
                  font="font-semibold"
                  py="py-4"
                  round="full"
                  hover="hover:bg-black"
                  bg="bg-[#FEDB00]"
                  color="black"
                />
              </div>
            </div>
            <div className="w-1/2 flex flex-col py-8 px-10 leading-10">
              <div className="text-2xl pt-6 pb-[30px] font-bold text-white">
                Practice Test Break
              </div>
              <div className="pb-[30px] text-white opacity-75 text-base border-b border-white">
                You can resume this practice test as soon as you're ready to
                move on. On test day, you'll wait until the clock counts down.
                Read below to see how breaks work on test day.
              </div>
              <div className="text-2xl py-[30px] font-bold text-white">
                Take a Break: Do Not Close Your Device
              </div>
              <div className=" text-white opacity-75">
                After the break, a Resume Testing Now button will appear and
                you'll start the next session.
              </div>
              <div className="text-lg font-bold py-6 text-white">
                Follow these rules during the break:
              </div>
              <div className="flex flex-col text-white opacity-75">
                <p>1. Do not disturb students who are still testing</p>
                <p>2. Do not exit the app or close your laptop.</p>
                <p>
                  3. Do not access phones, smartwatches, textbooks, notes, or
                  the internet.
                </p>
                <p>4. Do not eat or drink near any testing device.</p>
                <p>
                  5. Do not speak in the test room; outside the test room, do
                  not discuss the exam with anyone.
                </p>
              </div>
            </div>
          </div>
          <div className="h-[10%] w-full justify-start flex text-2xl text-white font-bold items-center pl-11">
            {getItem("userName")}
          </div>
        </>
      )}
    </div>
  );
};

export default ModuleBreakTime;
